export const NAVIGATE_CALLBACK = "/callback"
export const NAVIGATE_MEMBER_DASHBOARD = "/corporate/dashboard"
export const NAVIGATE_CORPORATE_TRANSFER = "/corporate/transfer"
export const NAVIGATE_CHANGE_PASSWORD = "/changepassword"
export const NAVIGATE_MEMBER_PROFILE = "/member/profile"
export const NAVIGATE_CORPORATE_PROFILE = "/corporate/profile"
export const NAVIGATE_REFERRAL = "/referral"
export const NAVIGATE_FEEDBACK = "/feedback"
export const NAVIGATE_MEMBER_LOGIN = "/auth/login"
export const NAVIGATE_CORPORATE_LOGIN = "/auth/login"
export const NAVIGATE_MEMBER_REGISTER = "/member/register"
export const NAVIGATE_CORPORATE_REGISTER = "/corporate/register"
export const NAVIGATE_FORGOT_PASSWORD = "/forgotpassword"
export const NAVIGATE_REACTIVATION = "/reactivation"
export const NAVIGATE_RESET_PASSWORD = "/resetpassword"
export const NAVIGATE_BUY = "/buy"
export const NAVIGATE_MEMBER_BOOKING = "/member/booking"
export const NAVIGATE_CORPORATE_BOOKING = "/corporate/booking"
export const NAVIGATE_MEMBER_CLAIMSUMMARY = "/member/claimsummary"
export const NAVIGATE_MEMBER_TRAVEL_COMPANION = "/member/travelcompanion"
export const NAVIGATE_MEMBER_CLAIMSUBMIT = "/member/claimsubmit"
export const NAVIGATE_CORPORATE_CLAIMSUMMARY = "/corporate/claimsummary"
export const NAVIGATE_CORPORATE_CLAIMSUBMIT = "/corporate/claimsubmit"
export const NAVIGATE_FAMILY_MEMBER_ADD = "/addfamilymember"
export const NAVIGATE_MILEAGE_CALCULATOR = "/mileagecalculator"
export const NAVIGATE_MEMBER_MYACTIVITY = "/member/myactivity"
export const NAVIGATE_CORPORATE_MYACTIVITY = "/corporate/myactivity"
export const NAVIGATE_MYFAMILY = "/myfamily1"
export const NAVIGATE_MEMBER_MYFLIGHT = "/member/myflights"
export const NAVIGATE_CORPORATE_MYFLIGHT = "/corporate/myflights"
export const NAVIGATE_TRANSFER = "/transfer"
export const NAVIGATE_PARTNERS = "/partners"
export const NAVIGATE_OFFERS = "/offers"
export const NAVIGATE_ACTIVATE = "/useractivation"
export const NAVIGATE_FAMILY_POOLING = "/myfamily"
export const NAVIGATE_CORPORATE_MANAGE_PEOPLE = "/corporate/manageusers"
export const NAVIGATE_MIGRATE_POINTS = "/member/migratepoints"
export const NAVIGATE_404 = "/404"
export const NAVIGATE_403 = "/403"
export const NAVIGATE_500 = "/500"
export const NAVIGATE_COMMON_ERROR = "/error"
export const NAVIGATE_MEMBER_OVERVIEW = "/member/overview"
export const NAVIGATE_CORPORATE_OVERVIEW = "/corporate/overview"
export const NAVIGATE_CLUB = "/clubmembership"
export const NAVIGATE_CLUB_PAYCONFIRM = `${NAVIGATE_CLUB}/subscribe`
export const NAVIGATE_CLUB_ADDBOOSTER = `${NAVIGATE_CLUB}/addboosterpack`
export const NAVIGATE_PROFILE_COMPLETION="/member/profilecompletion"
export const NAVIGATE_MEMBER_EXTEND_EXPIRY ="/member/extendexpiry"
export const NAVIGATE_LOGOUT = "/auth/logout"
export const NAVIGATE_RECURRING_TRANSFER = "/corporate/transfer/recurring"

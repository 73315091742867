import React, { Component } from 'react';
import CommonErrorPage from './CommonErrorPage';
import loadable from '@loadable/component';
import { ROUTER_CONFIG } from '../../components/common/config/routing';
const HeaderComponent = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'../../components/ui/header/index'));

export default class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, info) {
      // Display fallback UI
      this.setState({ hasError: true });
      // You can also log the error to an error reporting service
      console.error("Error: ",error," INFO: ",info)
      //logErrorToMyService(error, info);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <>
            <HeaderComponent includeHeader={false} apiTriggerConfig={{}} imageBannerConfig={{}} />
            <CommonErrorPage setErrorPageInfo={this.props.setErrorPageInfo} />
            <ROUTER_CONFIG.footer.component />
        </>;
      }
      return this.props.children;
    }
  }
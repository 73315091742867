import {
    isApiResponseAuthorized,
    getApiErrorMessage,
    isEmptyOrSpaces,
    getCurrentPageUri
} from '../../utils'

import {
    CONFIG_SECTION_DEFAULT, PROGRAM_TYPE_INDIVIDUAL, MASTER_REARRANGE, PROGRAM_TYPE_CORPORATE
} from '../../utils/Constants'

import {
    _URL_ACCOUNT_SUMMARY,
    _URL_MASTER_RETRIEVE,
    _URL_CONFIGURATION_SERVICE,
    _URL_LOGOUT,
    _URL_SEARCH_MEMBER,
    _URL_FETCH_FAMILY_MEMBERS,
    _URL_FETCH_PROFILE_IMAGE,
    _URL_RETRIEVE_CUSTOMER_PROFILE,
    _URL_FETCH_PROFILE_DETAILS,
    _URL_SOCIAL_LOGGED_USER,
    _URL_FETCH_QRCODE_IMAGE,
    _URL_RETRIEVE_NOMINEES,
    _URL_FETCH_AIRPORT_DETAILS,
    _URL_VALIDATE_MEMBER_DETAIL,
    _URL_ADD_DELETE_NOMINEE,
    _URL_RETRIEVE_RENEW_EXPIRY_POINT_DETAILS,
    _URL_RETRIEVE_PARTNER_DETAILS
} from '../../config/config'

import { doPost, doGet } from '../../utils/api'
import {
    getItemFromBrowserStorage,
    BROWSER_STORAGE_KEY_COMPANY_CODE,
    BROWSER_STORAGE_KEY_PROGRAM_CODE,
    BROWSER_STORAGE_KEY_DEFAULT_PROGRAM_CODE,
    BROWSER_STORAGE_KEY_MEMBERSHIP_NO,
    BROWSER_STORAGE_KEY_PROGRAM_TYPE,
    BROWSER_STORAGE_KEY_EMAIL,
    BROWSER_STORAGE_KEY_CUSTOMER_NO,
    setItemToBrowserStorage,
    BROWSER_STORAGE_KEY_REFRESH_TOKEN,
    BROWSER_STORAGE_KEY_DEFAULT_MEMBERSHIP_NO,
    clearBrowserStorage,
    BROWSER_STORAGE_TYPE_SESSION,
    BROWSER_STORAGE_KEY_TOKEN
} from '../../utils/storage.utils'
import { NAVIGATE_403, NAVIGATE_PROFILE_COMPLETION, NAVIGATE_500, NAVIGATE_CORPORATE_OVERVIEW, NAVIGATE_404 } from '../../utils/urlConstants'
import { startApiLoading, startButtonSpinner, stopApiLoading, stopButtonSpinner } from '../../components/fieldbank/loader/action'
import { memberTier } from '../../utils/tier.utils'
import { ROUTER_CONFIG } from '../../config/routing'
import { getLogoutMessageData } from '../../utils/common.utils'
import { redirectPage } from '../../utils/sso.utils'

export const ACTION_FETCH_CURRENT_USER_SUCCESS = "ACTION_FETCH_CURRENT_USER_SUCCESS"
export const ACTION_FETCH_CURRENT_USER_FAILURE = "ACTION_FETCH_CURRENT_USER_FAILURE"
export const ACTION_FETCH_PROFILEDATA_SUCCESS = "ACTION_FETCH_PROFILEDATA_SUCCESS"
export const ACTION_FETCH_PROFILEDATA_FAILURE = "ACTION_FETCH_PROFILEDATA_FAILURE"
export const FETCH_ACCOUNT_SUMMARY = "FETCH_ACCOUNT_SUMMARY"
export const FETCH_ACCOUNT_SUMMARY_ERROR = "FETCH_ACCOUNT_SUMMARY_ERROR"
export const MASTER_SET_GENDER = "MASTER_SET_GENDER";
export const MASTER_SET_ISD_CODES = "MASTER_SET_ISD_CODES";
export const SET_MASTER_DATA = "SET_MASTER_DATA";
export const SET_CONFIG_DATA = "SET_CONFIG_DATA"
export const RESET_ERROR = "RESET_ERROR"
export const ERROR = 'ERROR';
export const LOGOUT = "LOGOUT";
export const RESET_STATE = "RESET_STATE"
export const FETCH_USERINFO = 'FETCH_USERINFO';
export const FINDING_USER_ERROR = 'FINDING_USER_ERROR';
export const SET_FAMILY_MEMBERS = "SET_FAMILY_MEMBERS"
export const FETCH_PROFILE_IMAGE = 'FETCH_PROFILE_IMAGE';
export const FETCH_CUSTOMER_PROFILE = 'FETCH_CUSTOMER_PROFILE'
export const ACTION_PRIVILEGES = "SET_PRIVILEGES"
export const FETCH_QRCODE_IMAGE = 'FETCH_QRCODE_IMAGE';
export const FETCH_QRCODE_IMAGE_ERROR = 'FETCH_QRCODE_IMAGE_ERROR';
export const FETCH_ACCOUNT_NOMINEE_SUCCESS = 'FETCH_ACCOUNT_NOMINEE_SUCCESS';
export const FETCH_ACCOUNT_NOMINEE_FAILURE = 'FETCH_ACCOUNT_NOMINEE_FAILURE';
export const PAGE_REFERENCE = "PAGE_REFERENCE";
export const CURRENT_MENU = 'CURRENT_MENU';
export const SET_TIER_DETAILS = "SET_TIER_DETAILS";
export const SET_AIRPORT_DETAILS = "SET_AIRPORT_DETAILS";
export const SET_VALIDATE_MEMBER_DETAIL = "SET_VALIDATE_MEMBER_DETAIL"
export const RETRIEVE_RENEW_EXPIRY_POINT_DETAILS_SUCCESS = "RETRIEVE_RENEW_EXPIRY_POINT_DETAILS_SUCCESS"
export const SET_SELECTED_TAB = "SET_SELECTED_TAB"

export const ACTION_FETCH_DEFAULT_USER_PROFILEDATA_SUCCESS = "ACTION_FETCH_DEFAULT_USER_PROFILEDATA_SUCCESS";
export const ACTION_FETCH_DEFAULT_USER_PROFILEDATA_FAILURE = "ACTION_FETCH_DEFAULT_USER_PROFILEDATA_FAILURE";

export const ACTION_ADD_DELETE_NOMINEE = "ACTION_ADD_DELETE_NOMINEE"
export const ADD_OR_DELETE = "addOrDeleteNominee"

export const CSS_LOADER = "CSS_LOADER"
export const RETRIEVE_PARTNER_DETAILS_SUCCESS = "RETRIEVE_PARTNER_DETAILS_SUCCESS"
export const SET_URL_INFO = "SET_URL_INFO";
export const SET_CUSTOM_MESSAGE = "SET_CUSTOM_MESSAGE";


/**
 * Creates Action of type <i>_URL_SEARCH_MEMBER</i> with given data
 *
 * @function fetchUserInfo
 * @param {object} payload Object to be passed as data in Action
 * @returns Action of type <i>_URL_SEARCH_MEMBER</i>
 *
 */
export const fetchUserInfo = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("fetchUserInfo"))
        return await doPost(_URL_SEARCH_MEMBER, payload)
            .then((response) => {
                dispatch(stopApiLoading("fetchUserInfo"))
                dispatch({
                    type: FETCH_USERINFO,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchUserInfo"))
                dispatch({
                    type: FINDING_USER_ERROR,
                    payload: error
                })
            })
    };
};

export const fetchAccountSummary = (payload) => {
    if (!payload) {
        payload = {
            object: {
                companyCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
                programCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE),
                membershipNumber: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO),
                isBonusRequired: "Y",
                tierOptionsRequired: true
            }
        }
    }

    return async dispatch => {
        dispatch(startApiLoading("fetchAccountSummary"))
        return await doPost(_URL_ACCOUNT_SUMMARY, payload)
            .then(response => {
                dispatch(stopApiLoading("fetchAccountSummary"))
                dispatch({
                    type: FETCH_ACCOUNT_SUMMARY,
                    payload: response.data
                })
                return response.data.object;
            }).catch(error => {
                dispatch(stopApiLoading("fetchAccountSummary"))
                dispatch({
                    type: FETCH_ACCOUNT_SUMMARY_ERROR,
                    payload: error
                })
                isApiResponseAuthorized(error)
                if (error && error.response && error.response.status == 500) {
                    window.location.href = `${NAVIGATE_500}`
                }
            })
    }
}

export const setGenderData = payload => {
    return ({
        type: MASTER_SET_GENDER,
        payload
    })
}

export const fetchMasterData = (entityCode, masterEntityLookup, masterEntityLookupFilters) => {
    return async dispatch => {
        dispatch(startApiLoading(`fetchMasterData[${entityCode}]`))
        const requestBody = {
            "object": {
                "companyCode": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
                "programCode": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE),
                "entityCode": masterEntityLookup[entityCode]
            }
        }

        if (masterEntityLookupFilters && masterEntityLookupFilters[entityCode] && Object.keys(masterEntityLookupFilters[entityCode]).length) {
            requestBody.object["filterFieldDetail"] = masterEntityLookupFilters[entityCode]
        }

        dispatch({
            type: SET_MASTER_DATA,
            payload: { data: [], type: entityCode }
        });
        return await doPost(_URL_MASTER_RETRIEVE, requestBody)
            .then(response => {
                dispatch(stopApiLoading(`fetchMasterData[${entityCode}]`))
                if (response.data.object && response.data.object.masterRecordList) {
                    let { masterRecordList } = response.data.object
                    if (MASTER_REARRANGE[entityCode]) {
                        const rearrangeConfig = MASTER_REARRANGE[entityCode]
                        if (rearrangeConfig.defaultValue) {
                            let index = -1, defaultObj = {};
                            masterRecordList.some(function (entry, i) {
                                if (entry.CountryCode == rearrangeConfig.defaultValue) {
                                    index = i;
                                    defaultObj = entry;
                                    return true;
                                }
                            });
                            if (index != -1) {
                                masterRecordList.splice(index, 1);
                                masterRecordList.unshift(defaultObj);
                            }
                        }
                    }

                    dispatch({
                        type: SET_MASTER_DATA,
                        payload: { data: masterRecordList, type: entityCode }
                    });

                } else {
                    dispatch({
                        type: SET_MASTER_DATA,
                        payload: { data: [], type: entityCode }
                    });
                }
            }).catch(error => {
                dispatch(stopApiLoading(`fetchMasterData[${entityCode}]`))
                isApiResponseAuthorized(error)
            })
    }
}

export const fetchConfiguration = section => {
    let _URL = `${_URL_CONFIGURATION_SERVICE}/${section}`, canProceed = true
    if (section != CONFIG_SECTION_DEFAULT) {
        const companyCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE)
        const programCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE)
        canProceed = !isEmptyOrSpaces(companyCode) && !isEmptyOrSpaces(programCode)
        _URL += `/${companyCode}/${programCode}`
    }
    return async dispatch => {
        if(canProceed) {
            dispatch(startApiLoading(`fetchConfiguration[${section}]`))
            return await doGet(_URL)
                .then(response => {
                    dispatch(stopApiLoading(`fetchConfiguration[${section}]`))
                    dispatch({
                        type: SET_CONFIG_DATA,
                        payload: { config: response.data.object, type: section }
                    });
                }).catch(error => {
                    dispatch(stopApiLoading(`fetchConfiguration[${section}]`))
                    isApiResponseAuthorized(error)
                })
        } else {
            dispatch({
                type: SET_CONFIG_DATA,
                payload: { config: {}, type: section }
            });
        }
    }
}

export const resetError = () => {
    return ({
        type: RESET_ERROR
    })
}

export const setError = messageArray => {
    return {
        type: ERROR,
        payload: {
            error: messageArray
        }
    }
}

export const logOut = (loading = true, action = "self") => {
    resetError()
    const requestHeader = {
        "RefreshToken": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_REFRESH_TOKEN),
        "Authorization": "Bearer "+getItemFromBrowserStorage(BROWSER_STORAGE_KEY_TOKEN)
    }
    const logoutMessageParams = getLogoutMessageData(action)
    
    return async (dispatch) => {
        if (loading) {
            dispatch(startApiLoading("logOut"))
        }
        await doPost(_URL_LOGOUT, {}, requestHeader)
            .then(response => {
                dispatch(stopApiLoading("logOut"))
                dispatch({
                    type: LOGOUT,
                    payload: response.data.success
                })
                clearBrowserStorage()
                clearBrowserStorage(BROWSER_STORAGE_TYPE_SESSION)
                if (loading) {
                    window.location.href = `${ROUTER_CONFIG.default.postLogout}?${logoutMessageParams}`
                    // window.location.reload();
                }
            })
            .catch(error => {
                dispatch(stopApiLoading("logOut"))
                console.log("Error occured during logout: ", error)
                dispatch({
                    type: LOGOUT,
                    payload: true
                })
                clearBrowserStorage()
                clearBrowserStorage(BROWSER_STORAGE_TYPE_SESSION)
                if (loading) {
                    // window.location = `${ROUTER_CONFIG.default.postLogout}?message=errorMessage&t=seoTitleFailed`
                    window.location.href = `${ROUTER_CONFIG.default.postLogout}?${logoutMessageParams}`
                    // window.location.reload();
                }
            })
    }
}

export const clearLogoutData = () => {
    return dispatch => {
        dispatch({
            type: LOGOUT,
            payload: {}
        })
    }
}

export const clearState = () => {
    return dispatch => {
        dispatch({
            type: RESET_STATE,
            payload: {}
        })
    }
}

/**
 * Get family members api call
 * @author Alan Kuriakose
 * @param {object} params - parameters for api call
 */
export const getFamilyMembers = params => {
    return async dispatch => {
        dispatch(startApiLoading("getFamilyMembers"))
        await doPost(_URL_FETCH_FAMILY_MEMBERS, params)
            .then(response => {
                dispatch(stopApiLoading("getFamilyMembers"))
                const members = response.data.object;
                dispatch({
                    type: SET_FAMILY_MEMBERS,
                    payload: members
                })
            }).catch(error => {
                dispatch(stopApiLoading("getFamilyMembers"))
                console.log(error);
            })
    }
}

/**
 * Creates Action of type <i>_URL_FETCH_PROFILE_IMAGE</i> with given data
 *
 * @function fetchProfileImage
 * @param {object} payload Object to be passed as data in Action
 * @returns Action of type <i>_URL_FETCH_PROFILE_IMAGE</i>
 *
 */
export const fetchProfileImage = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("fetchProfileImage"))
        return await doPost(_URL_FETCH_PROFILE_IMAGE, payload)
            .then((response) => {
                dispatch(stopApiLoading("fetchProfileImage"))
                dispatch({
                    type: FETCH_PROFILE_IMAGE,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchProfileImage"))
                dispatch({
                    type: ERROR,
                    payload: error
                })
            })
    };
};

/**
 * Get the customer profile data
 * @author Somdas M
 * @param {object} payload - request body for api call
 */
export const fetchCustomerProfileData = () => {
    const payload = {
        "object": {
            "companyCode": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
            "programCode": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE),
            "customerNumber": getItemFromBrowserStorage(BROWSER_STORAGE_KEY_CUSTOMER_NO)
        }
    }
    return async dispatch => {
        dispatch(startApiLoading("fetchCustomerProfileData"))
        return await doPost(_URL_RETRIEVE_CUSTOMER_PROFILE, payload)
            .then((response) => {
                dispatch(stopApiLoading("fetchCustomerProfileData"))
                const object = response.data.object;
                dispatch({
                    type: FETCH_CUSTOMER_PROFILE,
                    payload: { object }
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchCustomerProfileData"))
                dispatch({
                    type: ERROR,
                    payload: error
                })
                isApiResponseAuthorized(error)
            })
    };
}

/**
 * @description API call for fetching profile data
 * @author Ajmal Aliyar
 */
export const fetchProfileData = () => {
    const requestBody = {
        object: {
            companyCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
            programCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE),
            membershipNumber: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO),
        }
    }
    return async dispatch => {
        dispatch(startApiLoading("fetchProfileData"))
        return await doPost(_URL_FETCH_PROFILE_DETAILS, requestBody)
            .then(response => {

                dispatch(stopApiLoading("fetchProfileData"))
                const changeKey = (oldKey, newKey, objectParam) => {
                    objectParam[newKey] = objectParam[oldKey];
                    delete objectParam[oldKey];
                }
                const deleteKeys = (keys, objectParam) => {
                    keys.forEach(key => {
                        delete objectParam[key];
                    })
                }
                const object = response.data.object;
                if (getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE) == PROGRAM_TYPE_INDIVIDUAL) {
                    // clean object
                    deleteKeys(['isAccrualValid', 'isRedemptionValid', 'memberArtefactDetail'], object);
                    object.paymentDetail = null;
                    // clean object.memberAccount
                    object.memberAccount.enrolmentDate = String('0' + new Date().toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }).replace(/ /ig, '-')).slice(-11);
                    changeKey('accountStatusCode', 'accountStatus', object.memberAccount);
                    changeKey('accountPeriodType', 'periodType', object.memberAccount);
                    changeKey('accountExpiryinMonths', 'period', object.memberAccount);
                    //Commented this to update the profile %
                    //deleteKeys(['memberPreferences', 'accrualSegment'], object.memberAccount);
                    // clean object.memberAccount.memberProfile
                    deleteKeys(['companyCode', 'membershipNumber', 'enrollmentSource', 'corporateInfo', 'enrollmentSourceCode', 'customerPassword'], object.memberAccount.memberProfile)
                    changeKey('membershipStatusCode', 'membershipStatus', object.memberAccount.memberProfile);
                    object.memberAccount.memberProfile.corporateInfo = null;
                }
                if (object.memberAccount.memberProfile && object.memberAccount.memberProfile.individualInfo) {
                    // update keys in object.memberAccount.memberProfile.individualInfo
                    if (!object.memberAccount.memberProfile.individualInfo.designation) {
                        object.memberAccount.memberProfile.individualInfo.designation = "";
                    }
                    // sort object.memberAccount.memberProfile.indivitualInfo.memberContactInfos
                    // const memberContactInfos = object.memberAccount.memberProfile.individualInfo.memberContactInfos.sort((a,b)=>String(a.addressType).localeCompare(b.addressType));
                    const homeAddress = object.memberAccount.memberProfile.individualInfo.memberContactInfos.find(ob => ob.addressType === 'H') || { addressType: 'H' };
                    const businessAddress = object.memberAccount.memberProfile.individualInfo.memberContactInfos.find(ob => ob.addressType === 'B') || { addressType: 'B' };
                    const memberContactInfos = [homeAddress, businessAddress];
                    deleteKeys(['memberContactInfos'], object.memberAccount.memberProfile.individualInfo)
                    object.memberAccount.memberProfile.individualInfo.memberContactInfos = memberContactInfos;
                }
                // console.log({object});
                dispatch({
                    type: ACTION_FETCH_PROFILEDATA_SUCCESS,
                    payload: { object }
                })
                if (object.areMandatoryFieldsFilled == false) {
                    window.location = `${NAVIGATE_PROFILE_COMPLETION}`
                }


            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchProfileData"))
                if (error && error.response && error.response.data && error.response.data.error) {
                    const payload = getApiErrorMessage(error.response.data.error).filter(ob => ob);
                    if (payload.length === 0) {
                        payload.push("Request failed")
                    }
                    dispatch({
                        type: ERROR,
                        payload: { error: payload }
                    })
                }
            })
    };
}

/**
 * @description API call for fetching profile data
 * @author Geo George
 */
export const fetchDefaultUserProfileData = () => {
    const requestBody = {
        object: {
            companyCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
            programCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_PROGRAM_CODE),
            membershipNumber: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_MEMBERSHIP_NO)
        }
    }
    return async dispatch => {
        dispatch(startApiLoading("fetchDefaultUserProfileData"))
        return await doPost(_URL_FETCH_PROFILE_DETAILS, requestBody)
            .then(response => {
                dispatch(stopApiLoading("fetchDefaultUserProfileData"))
                dispatch({
                    type: ACTION_FETCH_DEFAULT_USER_PROFILEDATA_SUCCESS,
                    payload: response.data.object
                })
                if (response.data.object.areMandatoryFieldsFilled == false) {
                    window.location = `${NAVIGATE_PROFILE_COMPLETION}`
                }
            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchDefaultUserProfileData"))
                dispatch({
                    type: ACTION_FETCH_DEFAULT_USER_PROFILEDATA_FAILURE,
                    payload: error
                })
            })
    };
}
/**
 * @description API call for fetching user/current login user data
 * @author Rohith
 */
export const fetchCurrentLoginUserData = (defaultConfig) => {
    return async dispatch => {
        dispatch(startApiLoading("fetchCurrentLoginUserData"))
        return await doGet(_URL_SOCIAL_LOGGED_USER)
            .then(response => {
                dispatch(stopApiLoading("fetchCurrentLoginUserData"))
                const programType = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE)
                const companyCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE)
                const programCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE)
                const defaultProgramCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_PROGRAM_CODE)

                let loginProgram = response.data.userDetails.programs[programType + "Info"];
                loginProgram = loginProgram ? loginProgram.find(lp =>
                    lp.companyCode == companyCode &&
                    lp.programCode == programCode
                ) : undefined
                if (defaultConfig) {
                    const defaultProgram = defaultConfig.programs.find(program => {
                        return program.isActive && program.isDefault
                    })
                    if (defaultProgram) {
                        let defaultLoginProgram = response.data.userDetails.programs[defaultProgram.programType + "Info"];
                        defaultLoginProgram = defaultLoginProgram ? defaultLoginProgram.find(lp =>
                            lp.companyCode == companyCode &&
                            lp.programCode == defaultProgramCode
                        ) : undefined
                        if (defaultLoginProgram) {
                            setItemToBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_MEMBERSHIP_NO, defaultLoginProgram.membershipNumber)
                            if (!loginProgram) loginProgram = defaultLoginProgram
                        }
                    }
                }
                if (loginProgram) {
                    const customerNumber = loginProgram.nominee ? loginProgram.nominee.customerNumber : loginProgram.customerNumber ? loginProgram.customerNumber : ""
                    const membershipNumber = loginProgram.membershipNumber
                    setItemToBrowserStorage(BROWSER_STORAGE_KEY_EMAIL, response.data.email)
                    setItemToBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO, membershipNumber)
                    setItemToBrowserStorage(BROWSER_STORAGE_KEY_CUSTOMER_NO, customerNumber)
                    const privilegeMasterData = loginProgram.nominee ? loginProgram.nominee.privileges : loginProgram.privileges
                    if (privilegeMasterData && Object.keys(privilegeMasterData.ui).length > 0) {
                        dispatch({
                            type: ACTION_PRIVILEGES,
                            payload: privilegeMasterData.ui
                        })
                    }
                } else {
                    window.location = `${NAVIGATE_403}`
                }
                dispatch({
                    type: ACTION_FETCH_CURRENT_USER_SUCCESS,
                    payload: response.data
                })
                if(getCurrentPageUri() == ROUTER_CONFIG.default.url) {
                    if (getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE) === PROGRAM_TYPE_CORPORATE) {
                        window.location = `${NAVIGATE_CORPORATE_OVERVIEW}`
                    } else if (getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE) === PROGRAM_TYPE_INDIVIDUAL) {
                        redirectPage()
                    } else {
                        window.location = `${NAVIGATE_404}`
                    }
                }
                return response
            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchCurrentLoginUserData"))
                dispatch({
                    type: ACTION_FETCH_CURRENT_USER_FAILURE,
                    payload: error
                })
                isApiResponseAuthorized(error)
            })
    };
}

/**
 * @function getQrCodeImage
 * @param {object} payload Object to be passed as data in Action
 * @returns Action of type <i>_URL_FETCH_QRCODE_IMAGE</i>
 */
export const getQrCodeImage = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("getQrCodeImage"))
        return await doPost(_URL_FETCH_QRCODE_IMAGE, payload)
            .then((response) => {
                dispatch(stopApiLoading("getQrCodeImage"))
                dispatch({
                    type: FETCH_QRCODE_IMAGE,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("getQrCodeImage"))
                dispatch({
                    type: FETCH_QRCODE_IMAGE_ERROR,
                    payload: error
                })
            })
    }
}

/**
 * @description API call for fetching account nominee
 * @author Rohith
 */
export const fetchAccountNominee = () => {
    const requestBody = {
        object: {
            companyCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
            programCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE),
            membershipNumber: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO),
            nomineeReferenceNumber: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_CUSTOMER_NO)
        }
    }
    return async dispatch => {
        dispatch(startApiLoading("fetchAccountNominee"))
        return await doPost(_URL_RETRIEVE_NOMINEES, requestBody)
            .then(response => {
                dispatch(stopApiLoading("fetchAccountNominee"))
                dispatch({
                    type: FETCH_ACCOUNT_NOMINEE_SUCCESS,
                    payload: response.data
                })

            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchAccountNominee"))
                dispatch({
                    type: FETCH_ACCOUNT_NOMINEE_FAILURE,
                    payload: error
                })
                isApiResponseAuthorized(error)
                if (error && error.response && error.response.status == 500) {
                    window.location.href = `${NAVIGATE_500}`
                }
            })
    };
}

export const setPageReference = (pageReference) => {
    return dispatch => {
        dispatch({
            type: PAGE_REFERENCE,
            payload: pageReference
        })
    }
}



/**
 * Creates Action of type <i>CURRENT_BREADCRUMB</i> with given data
 *
 * @function setMenuReference
 * @param {string} menuReference name to be passed as current breadcrumb in Action
 * @returns Action of type <i>CURRENT_BREADCRUMB</i>
 *
 */

export const setMenuReference = (menuReference) => {
    return dispatch => {
        dispatch({
            type: CURRENT_MENU,
            payload: menuReference
        });
    };
};

/**
 * @description Action to set the MemberTier based on the Base and Sub Tier
 * @param {*} accountsummary
 * @param {*} config
 * @author Gowthaman A
 */
export const setTierDetails = (accountsummary, config, t) => {
    const response = memberTier(accountsummary, config, t)
    return dispatch => {
        dispatch({
            type: SET_TIER_DETAILS,
            payload: response
        });
    };
};

/**
 *
 * @param {*} params
 * @description Method to get the Origin and Destination Airports to populate in SearchFlightComponent/ Dashboard
 * @author Gowthaman A
 */
export const getAirportDetails = (params) => {
    return async dispatch => {
        dispatch(startApiLoading("getAirportDetails"))
        await doPost(_URL_FETCH_AIRPORT_DETAILS, params)
            .then(response => {
                dispatch(stopApiLoading("getAirportDetails"))
                dispatch({
                    type: SET_AIRPORT_DETAILS,
                    payload: response.data.object
                })
            }).catch(error => {
                dispatch(stopApiLoading("getAirportDetails"))
                dispatch({
                    type: ERROR,
                    payload: error
                })
            })
    }
}

/**
 *
 * @param {*} params
 */
export const validateMemberDetails = (params) => {
    return async dispatch => {
        dispatch(startApiLoading("validateMemberDetails"))
        return await doPost(_URL_VALIDATE_MEMBER_DETAIL, params)
            .then((response) => {
                dispatch(stopApiLoading("validateMemberDetails"))
                dispatch({
                    type: SET_VALIDATE_MEMBER_DETAIL,
                    payload: response.data.object
                })
            })
            .catch(error => {
                console.log("commonAction ", error.response.data);
                dispatch(stopApiLoading("validateMemberDetails"))
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
};

export const clearValidateResponse = () => {
    return async dispatch => {
        dispatch({
            type: SET_VALIDATE_MEMBER_DETAIL,
            payload: {}
        })
    }
}


/**
* Action call to add or delete nominee
* @param {JSON} payload Request payload to be dispatched
* @param {String} id
*
* @author Geo George
*/

export const addOrDeleteNominee = (payload, id) => {
    return async dispatch => {
        dispatch({
            type: ACTION_ADD_DELETE_NOMINEE,
            payload: {}
        })
        dispatch(startButtonSpinner(id, ADD_OR_DELETE))
        await doPost(_URL_ADD_DELETE_NOMINEE, payload)
            .then((response) => {
                dispatch(stopButtonSpinner(id, ADD_OR_DELETE))
                dispatch({
                    type: ACTION_ADD_DELETE_NOMINEE,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, ADD_OR_DELETE))
                dispatch({
                    type: ACTION_ADD_DELETE_NOMINEE,
                    payload: { error: getApiErrorMessage(error.response.data) }
                })
            })
    }
}

export const loadStyle = (value = false) => {
    return dispatch => {
        dispatch({
            type: CSS_LOADER,
            payload: value
        })
    }
}

/**
 * Action call to retrieve extend/renew expiry details.
 * @param {JSON} payload Request payload to be dispatched
 * @author Amrutha J Raj
 */

export const retrieveRenewOrExpiryPointDetails = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("retrieveRenewOrExpiryPointDetails"))
        await doPost(_URL_RETRIEVE_RENEW_EXPIRY_POINT_DETAILS, payload)
            .then((response) => {
                dispatch(stopApiLoading("retrieveRenewOrExpiryPointDetails"))
                dispatch({
                    type: RETRIEVE_RENEW_EXPIRY_POINT_DETAILS_SUCCESS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("retrieveRenewOrExpiryPointDetails"))
            })
    };
}


export const setSelectedTab = (selectedTab="") => {
    return dispatch=>{
        dispatch({
            type: SET_SELECTED_TAB,
            payload: selectedTab
        })
    }
}

/**
 * Action call to get partner details
 * @param {JSON} payload Request payload to be dispatched
 * @author Sojimon C Y
 */

 export const retrievePartnerDetails = (param , fieldType) => {
    return async dispatch => {
        await doPost(_URL_RETRIEVE_PARTNER_DETAILS, param)
            .then((response) => {
                dispatch({
                    type: RETRIEVE_PARTNER_DETAILS_SUCCESS,
                    payload: response?.data?.object?.partnerDetailsList,
                    fieldType
                })
            })
            .catch((error) => {
                
            })
    };
}

/**
 * Action call to set current and Previous URL for Analytics
 * @author Sojimon C Y
 */

export const setURLInfo = (param) => {
    return dispatch=>{
        dispatch({
            type: SET_URL_INFO,
            payload: param
        })
    }
}

/**
 * Action call to set customError Message for Analytics
 * @author Sojimon C Y
 */

 export const setCustomMessage = (param) => {
    return dispatch=>{
        dispatch({
            type: SET_CUSTOM_MESSAGE,
            payload: param
        })
    }
}

import React, { Component } from 'react';
import parse from 'html-react-parser';
import Button from '../../common/components/fieldbank/Button';
import history from '../../common/utils/history';
import { browserName } from "../../../pages/titleMapper";

class CommonMessage extends Component {

    getImage = (image, type, imgAlt) => {
        if(type == "inline") {
            return parse(image)
        } else {
            return <img src={image} alt={imgAlt?imgAlt:""} />
        }
    }

    handleOnClick = (button) => {
        // history.push(`#${button.link}`)
        window.location.href = `${button.link}`
        if(button.forceReload) window.location.reload()
    }
    componentDidMount() {
        if (this.props.accessibilityRef) {
            this.props.accessibilityRef.current.focus()
            this.props.accessibilityRef.current.tabIndex = "-1"
        }
    }

    render() {
        const { image, imageType, imgAlt, title, description1, description2, buttons, resetMessage, akwardMessage } = this.props
        const { pathname } = window.location
        return (
            <div className={`status-page ${image?"status-page--with-pic":"text-center"}`}>
                <div className="status-page__inner">
                    <div className="row">
                        {
                            image ?
                            <div className="col-lg-4 col-md-4 status-page_img">
                                {
                                    this.getImage(image, imageType, imgAlt)
                                }
                            </div>:<></>
                        }
                        <div id="id-error-message" className="col status-page_description">
                            {resetMessage ? <div className= "sr-only" ref={this.props.accessibilityRef} tabIndex={0}>{resetMessage} {browserName}`</div>:<></>}
                            {akwardMessage ? <div className= "sr-only" ref={this.props.accessibilityRef} tabIndex={0}>{akwardMessage}</div>:<></>}
                            {title ? <h2 tabindex="0" aria-label={parse(title).props.children} role={alert}>{parse(title)}</h2> : <></>}

                            {description1 ? <p >{description1}</p> : <></>}
                            {description2 ? <p >{description2}</p> : <></>}
                            {
                                buttons ?
                                <div className="btn-wrap">
                                    {
                                        buttons.map( (button, idx) => {
                                            if(button.link != pathname) {
                                                return <Button
                                                    className={button.className?button.className:"btn btn-primary"}
                                                    handleOnClick={() => this.handleOnClick(button)}
                                                    key={button.id?button.id:idx}
                                                    id={button.id?button.id:idx}
                                                    data-test={button.id?button.id:idx}
                                                    label={button.label?button.label:""}
                                                    ariaLabel={button.label?button.label:""}
                                                />
                                            }
                                        })
                                    }
                                </div>:<></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CommonMessage;
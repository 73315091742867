export const LAYOUT_ORDER_MEMBERCARD = 'memberCard'
export const LAYOUT_ORDER_USEFULLINKS = 'usefulLinkGroup'
export const LAYOUT_ORDER_FAMILYCARD = 'familyCard'
export const ID_SUBSCRIBE_BTN = "subscribe_btn"
export const DUPLICATE_SUBSCRIPTION_MESSAGE = "Contact with the external id already exists"
export const PROMO_FOOTER_BUTTON = "promo_footer_button"
export const FOOTER_USER_GUIDE_LINK = "footer_user_guide_link";
export const FOOTER_FAQ_LINK = "footer_faq_link";
export const FOOTER_HELP_LINK = "footer_help_link";
export const FOOTER_APPLE_STORE_LINK = "footer_apple_store_link";
export const FOOTER_GOOGLE_PLAY_LINK = "footer_google_play_link";
export const FOOTER_FACEBOOK_LINK = "footer_facebook_link";
export const FOOTER_INSTAGRAM_LINK = "footer_instagram_link";
export const FOOTER_LINKEDIN_LINK = "footer_linkedin_link";
export const FOOTER_TERMS_AND_CONDITION_LINK = "footer_terms_and_condition_link";
export const FOOTER_TERMS_OF_USE_LINK = "footer_terms_of_use_link";
export const FOOTER_PRIVACY_LINK = "footer_privacy_link";
export const FOOTER_LOGO_LINK = "footer_logo_link";
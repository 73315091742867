import { startButtonSpinner, stopButtonSpinner } from "../../common/components/fieldbank/loader/action";
import {
  _URL_FEED_BACK_CREATION,
  _URL_MASTER_RETRIEVE,
  _URL_REDEEM_BENEFIT,
} from "../../common/config/config";
import { getApiErrorMessage } from "../../common/utils";
import { doPost } from "../../common/utils/api";

export const SET_REDEEM_BENEFIT = "SET_REDEEM_BENEFIT";

export const submitRedeem = (payload, id, headers, t) => {
  return async (dispatch) => {
    dispatch(startButtonSpinner(id, "submitRedeem"))
    dispatch({
      type: SET_REDEEM_BENEFIT,
      payload: {},
    });

    await doPost(_URL_REDEEM_BENEFIT, payload, headers)
      .then((res) => {
        dispatch(stopButtonSpinner(id, "submitRedeem"))
        if (res.data) {
          dispatch({
            type: SET_REDEEM_BENEFIT,
            payload: res.data,
          });
        }
      })
      .catch((error) => {
        dispatch(stopButtonSpinner(id, "submitRedeem"))
        dispatch({
          type: SET_REDEEM_BENEFIT,
          payload: {
            error: getApiErrorMessage(error.response.data),
            data: error.response.data,
          },
        });
      });
  };
};

import { isEmptyOrSpaces } from ".";

/**
 * Method to add any script from env config
 * @author Rohith
 * @param {*} scripts scripts
 * @param {*} callback callback
 */
export function scriptLoader(scripts, callback) {

    var count = scripts.length;
    function urlCallback(scriptConfig) {
        return function () {
            if (count < 1) {
                callback();
            }
        };
    }

    function loadScript(scriptConfig) {
        var { isExternal, script, id } = scriptConfig
        var element = undefined, isNewElement = false
        if(isEmptyOrSpaces(id)) {
            element = document.createElement('script')
            isNewElement = true
        } else {
            element = document.getElementById(id)
        }
        if(element) {
            if(!isExternal) {
                element.innerHTML = script
                element.onload = urlCallback(scriptConfig)
            } else {
                element.setAttribute('src', script)
                element.onload = urlCallback(scriptConfig)
            }
            if(isNewElement) document.head.appendChild(element)
        }
    }

    for (var scriptConfig of scripts) {
        loadScript(scriptConfig);
    }
};
import React, { Suspense } from 'react';
import {
    useLocation,
    withRouter
} from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withSuspense } from '../../src/components/common/utils';
import { mapPageTitleAndSubtitle } from './titleMapper'
import { useState } from 'react';
import { useEffect } from 'react';

const TitleAnnouncer = props => {
    const titleRef = React.createRef()
    const { pathname } = useLocation()
    const [titleContent, setTitleContent] = useState("");

    useEffect(() => {
        setTitleContent("")
        let title = mapPageTitleAndSubtitle(pathname, props.t, props);
        setTitleContent(title)
        titleRef?.current?.focus();
    }, [pathname])

    return (
        <div>
            {titleContent && <>
                <span aria-live='assertive' aria-atomic="true" className='sr-only'>{titleContent + props.t("default.pageTitle")}</span>
            <p
                aria-hidden="true"
                ref={titleRef}
                className={'sr-only-titleAnnouncer'}
            >
                {props.t("default.pageTitle")}
            </p>
            </>}

        </div>
    )
}
export default withSuspense()(connect(null, null)(withTranslation()(withRouter(TitleAnnouncer))))

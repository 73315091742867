import { SET_REDEEM_BENEFIT } from "./actions";

const initialState = {};

export default function benefitReducer(state, action) {
  if (!state) state = initialState;
  const { type, payload } = action;
  if (type === SET_REDEEM_BENEFIT) {
    return {
      ...state,
      redeemBenefitDetails: payload,
    };
  } else {
    return state;
  }
}

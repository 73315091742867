//Storage Types
export const BROWSER_STORAGE_TYPE_LOCAL = 'local'
export const BROWSER_STORAGE_TYPE_SESSION = 'session'

//Local Storage keys
export const BROWSER_STORAGE_KEY_i18_LANG = "language"
export const BROWSER_STORAGE_KEY_MEMBERSHIP_NO = "memNo"
export const BROWSER_STORAGE_KEY_DEFAULT_MEMBERSHIP_NO = "defaultMemNo"
export const BROWSER_STORAGE_KEY_PROGRAM_CODE = "programCode"
export const BROWSER_STORAGE_KEY_DEFAULT_PROGRAM_CODE = "defaultProgramCode"
export const BROWSER_STORAGE_KEY_COMPANY_CODE = "companyCode"
export const BROWSER_STORAGE_KEY_CURRENCY_CODE = "currencyCode"
export const BROWSER_STORAGE_KEY_REQUEST_TIMEOUT = "requestTimedOutInMs"
export const BROWSER_STORAGE_KEY_FIRST_TIME_USER = "firstTimeUser"
export const BROWSER_STORAGE_KEY_TOKEN = "token"
export const BROWSER_STORAGE_KEY_RESPOMSE = "response"
export const BROWSER_STORAGE_KEY_REFRESH_TOKEN = "refresh_token"
export const BROWSER_STORAGE_KEY_PROGRAM_TYPE = "programType"
export const BROWSER_STORAGE_KEY_CUSTOMER_NO = "customerNo"
export const BROWSER_STORAGE_KEY_EMAIL = "email"
export const BROWSER_STORAGE_KEY_APP_ENV = "APP_ENV"
export const BROWSER_STORAGE_KEY_IS_REFRESH_TOKEN_FETCHING = "isRefreshTokenFetchInprogress"
export const BROWSER_STORAGE_KEY_DATE_FORMAT = "dateFormat"
export const BROWSER_STORAGE_KEY_DATE_TIME_FORMAT = "dateTimeFormat"

//Session storage keys
export const BROWSER_STORAGE_KEY_PARAMS = "params"
export const BROWSER_STORAGE_KEY_BASEPOINTS = "basePoints"
export const BROWSER_STORAGE_KEY_TRANSACTION_ID = "transactionId"
export const BROWSER_STORAGE_KEY_ACTIVITY_NUMBER = "activityNumber"
export const BROWSER_STORAGE_KEY_ACTIVITY_TYPE = "activityType"
export const BROWSER_STORAGE_KEY_ACTIVITY_CODE = "activityCode"
export const BROWSER_STORAGE_KEY_ACTIVITY_NAME = "activityName"
export const BROWSER_STORAGE_KEY_AMOUNT_COLLECTED = "amountCollected"
export const BROWSER_STORAGE_KEY_PURCHASE_POINTS = "purchasePoints"
export const BROWSER_STORAGE_KEY_TOTAL_MILES = "totalMiles"
export const BROWSER_STORAGE_KEY_ENROL = "enrol"
export const BROWSER_STORAGE_KEY_BOOK = "book"
export const BROWSER_STORAGE_KEY_MISSING_POINTS = "missingPoints"
export const BROWSER_STORAGE_KEY_POINT_DETAILS = "pointDetails"
export const BROWSER_STORAGE_KEY_SELECTED_TAB = "selectedTab"
export const BROWSER_STORAGE_KEY_UPGRADED_TIER = "upgradedTier"


/**
 * Sets value to browser storage of following types : 
 *  1. BROWSER_STORAGE_TYPE_LOCAL
 *  2. BROWSER_STORAGE_TYPE_SESSION
 *  
 * @param {string} itemKey Key for the value to be inserted
 * @param {string} value The value
 * @param {string} storageType if not specified, BROWSER_STORAGE_TYPE_LOCAL will be default
 * 
 * @author Ajmal V Aliyar
 */
export const setItemToBrowserStorage = (itemKey, value, storageType = BROWSER_STORAGE_TYPE_LOCAL) => {
    if(storageType == BROWSER_STORAGE_TYPE_SESSION){
        window.sessionStorage.setItem(itemKey, value)
    } else {
        window.localStorage.setItem(itemKey, value)
    }
}

/**
 * Gets value from browser storage of following types : 
 *  1. BROWSER_STORAGE_TYPE_LOCAL
 *  2. BROWSER_STORAGE_TYPE_SESSION
 * 
 * @param {string} itemKey Key of the value to be fetched
 * @param {string} storageType if not specified, BROWSER_STORAGE_TYPE_LOCAL will be default
 * 
 * @author Ajmal V Aliyar
 */
export const getItemFromBrowserStorage = (itemKey, storageType = BROWSER_STORAGE_TYPE_LOCAL) => {
    if(storageType == BROWSER_STORAGE_TYPE_SESSION){
        return window.sessionStorage.getItem(itemKey)
    } else {
        return window.localStorage.getItem(itemKey)
    }
}

/**
 * Removes item from browser storage of following types : 
 *  1. BROWSER_STORAGE_TYPE_LOCAL
 *  2. BROWSER_STORAGE_TYPE_SESSION
 * 
 * @param {string} itemKey Key of the item to be removed
 * @param {string} storageType if not specified, BROWSER_STORAGE_TYPE_LOCAL will be default
 * 
 * @author Ajmal V Aliyar
 */
export const removeItemFromBrowserStorage = (itemKey, storageType = BROWSER_STORAGE_TYPE_LOCAL) => {
    if(storageType == BROWSER_STORAGE_TYPE_SESSION){
        return window.sessionStorage.removeItem(itemKey)
    } else {
        return window.localStorage.removeItem(itemKey)
    }
}

/**
 * Clears browser storage of following types : 
 *  1. BROWSER_STORAGE_TYPE_LOCAL
 *  2. BROWSER_STORAGE_TYPE_SESSION
 * 
 * @param {string} storageType if not specified, BROWSER_STORAGE_TYPE_LOCAL will be default
 * 
 * @author Ajmal V Aliyar
 */
export const clearBrowserStorage = (storageType=BROWSER_STORAGE_TYPE_LOCAL) => {
    if(storageType == BROWSER_STORAGE_TYPE_SESSION){
        window.sessionStorage.clear()
    } else {
        const lang = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_i18_LANG)
        window.localStorage.clear()
        setItemToBrowserStorage(BROWSER_STORAGE_KEY_i18_LANG, lang)
    }
}
import { store } from "../../../../common/middleware/store";
export const API_LOADING_START = "API_LOADING_START"
export const API_LOADING_STOP = "API_LOADING_STOP"
export const BTN_LOADING_START = "BTN_LOADING_START"
export const BTN_LOADING_STOP = "BTN_LOADING_STOP"
export const SET_SPLASH_MESSAGE_DATA = "SET_SPLASH_MESSAGE_DATA"

export const startApiLoading = (actionName) => {
    return dispatch => {
        dispatch({
            type: API_LOADING_START,
            payload: {
                pointer: 1,
                actionName
            }
        })
        let obj = {};
        obj[actionName] = new Date().getTime();
        if(!checkActionAlreadyExist(actionName)) {
                dispatch({type: SET_SPLASH_MESSAGE_DATA, payload: obj})
        }
    };
};

export const stopApiLoading = (actionName) => {
    return dispatch => {
        dispatch({
            type: API_LOADING_STOP,
            payload: {
                pointer: -1,
                actionName
            }
        })
        if(checkActionAlreadyExist(actionName)) {
            dispatch({type: SET_SPLASH_MESSAGE_DATA, payload: updateActionList(actionName)})
        }
    };
};

export const startButtonSpinner = (id, actionName) => {
    return dispatch => {
        dispatch(startApiLoading(actionName))
        dispatch({
            type: BTN_LOADING_START,
            payload: {
                id
            }
        })
    };
};

export const stopButtonSpinner = (id, actionName) => {
    return dispatch => {
        dispatch(stopApiLoading(actionName))
        dispatch({
            type: BTN_LOADING_STOP,
            payload: {
                id
            }
        })
    };
};

export const checkActionAlreadyExist = (actionName) => {
    let splashMessageData = store?.getState()?.loaderReducer?.splashMessageData || {}
    // Checking action name exist in the object    
    if(actionName in splashMessageData) {
        return true;
    }
    return false;
}
export const updateActionList = (actionName) => {
    let splashMessageData = store?.getState()?.loaderReducer?.splashMessageData || {}
    if(actionName in splashMessageData) {
        delete splashMessageData[actionName]
        return splashMessageData;
    }
}

import {
    NAVIGATE_MEMBER_LOGIN,
    NAVIGATE_FORGOT_PASSWORD,
    NAVIGATE_CORPORATE_TRANSFER,
    NAVIGATE_CORPORATE_CLAIMSUMMARY,
    NAVIGATE_CORPORATE_OVERVIEW,
    NAVIGATE_LOGOUT,
    NAVIGATE_CORPORATE_MYACTIVITY,
    NAVIGATE_RESET_PASSWORD,
    NAVIGATE_CORPORATE_CLAIMSUBMIT,
    NAVIGATE_CHANGE_PASSWORD,
    NAVIGATE_ACTIVATE,
    NAVIGATE_REACTIVATION
} from '../components/common/utils/urlConstants'

export const browserName = (function (agent) {        switch (true) {
    case agent.indexOf("edge") > -1: return "MS Edge";
    case agent.indexOf("edg/") > -1: return "Edge ( chromium based)";
    case agent.indexOf("opr") > -1 && !!window.opr: return "Opera";
    case agent.indexOf("chrome") > -1 && !!window.chrome: return "Chrome";
    case agent.indexOf("trident") > -1: return "MS IE";
    case agent.indexOf("firefox") > -1: return "Mozilla Firefox";
    case agent.indexOf("safari") > -1: return "Safari";
    default: return "other";
}
})(window.navigator.userAgent.toLowerCase());

export const titles =
{
    login: {
        pageMainTitle: "login.pageTitle"
    },
    forgotpassword: {
        pageMainTitle: "forgotpsw.title"
    },
    transferpoints: {
        pageMainTitle: "feedBack_v2.title"
    },
    resetPassword: {
        pageMainTitle: "resetPsw.title"
    },
    pointsActivity: {
        pageMainTitle: "my_activity.activity_statement"
    },
    userActivation:{
        pageMainTitle: "user_activation.title"
    },
    reactivation:{
        pageMainTitle: "reactivation.talkback_title"
    },
    claimSummary: {
        pageMainTitle: "claim_summary.title"
    },
    claimSubmit: {
        pageMainTitle: "claimsubmit.title"
    },
    changePassword: {
        pageMainTitle: "security.changePasswordtitle"
    },
    homePage: {
        pageMainTitle: "overview.pageTitle"
    },
    logOutPage: {
        pageMainTitle: "logout.lod",
        pageSubTitle: "Accelerate Loyalty member portal",
        autoLogouPageMainTitle: "logout.alotTitle"
    },
    awkwardPage:{
        pageMainTitle: "errorMessages.talkback_title"
    }
}
export const mapPageTitleAndSubtitle = (path, t, props) => {
    switch (path) {
        case NAVIGATE_MEMBER_LOGIN:
            return t(titles.login.pageMainTitle)
        case NAVIGATE_FORGOT_PASSWORD:
            return t(titles.forgotpassword.pageMainTitle)
        case NAVIGATE_CORPORATE_TRANSFER:
            return t(titles.transferpoints.pageMainTitle)
        case NAVIGATE_RESET_PASSWORD:
            return t(titles.resetPassword.pageMainTitle)
        case NAVIGATE_CORPORATE_CLAIMSUMMARY:
            return t(titles.claimSummary.pageMainTitle)
        case  NAVIGATE_CHANGE_PASSWORD:
            return t(titles.changePassword.pageMainTitle)
        case NAVIGATE_CORPORATE_OVERVIEW:
            return t(titles.homePage.pageMainTitle)
        case NAVIGATE_CORPORATE_CLAIMSUBMIT:
            return t(titles.claimSubmit.pageMainTitle)
        case NAVIGATE_CORPORATE_MYACTIVITY:
            return t(titles.pointsActivity.pageMainTitle)
        case NAVIGATE_ACTIVATE:
            return t(titles.userActivation.pageMainTitle)
        case NAVIGATE_REACTIVATION:
            return t(titles.reactivation.pageMainTitle)
        case NAVIGATE_LOGOUT:
            let params = new URLSearchParams(props?.location?.search)
            if(params.get('ti') == "lot") {
                document.title = `${t("logout.title")} | ${t("default.pageTitle")}`;
                return t(titles.logOutPage.pageSubTitle) + " " +  t(titles.logOutPage.pageMainTitle);
            }  else {
                document.title = `${t(titles.logOutPage.autoLogouPageMainTitle)} | ${t("default.pageTitle")}`;
                return t(titles.logOutPage.autoLogouPageMainTitle);
            }
        default:
            return t(titles.awkwardPage.pageMainTitle);
            
    }
}

export const FIELDTYPE_CHECKBOXGROUP = 'C'
export const FIELDTYPE_DROPDOWN = 'B'
export const FIELDTYPE_RADIOBUTTONGROUP = 'R'
export const FIELDTYPE_TABLE = 'TABLE'
export const FIELDTYPE_TEXTFIELD = 'TF'
export const FIELDTYPE_POINT_TEXT = 'PT'
export const FIELDTYPE_TEXTAREA = 'TA'
export const FIELDTYPE_DROPDOWN_TEXTFIELD = 'B_TF'
export const FIELDTYPE_DATE = "D"
export const FIELDTYPE_CHECKBOX = "CB"
export const FIELDTYPE_PASSWORD = "P"
export const FIELDTYPE_SOCIAL_MEDIA = "SM"
export const FIELDTYPE_NONE = "NONE"
export const FIELDTYPE_HORIZONTAL_LINE = "HL"
export const FIELDTYPE_CUSTOM_COUNTY_DROPDOWN = "CD"
export const FIELDTYPE_CUSTOM_SEARCH_DROPDOWN = "SD"
export const FIELDTYPE_FILTER_DROPDOWN = "FD"
export const FIELDTYPE_TEXTFIELD_WITH_BUTTON = "TB"
export const FIELDTYPE_SECURITY_QUESTION = "SQ"
export const FIELDTYPE_MULTI_VALUE_TEXTFIELD = "MT"
export const FIELDTYPE_FILEUPLOAD = "FU"
export const FIELDTYPE_RECAPTCHA = "RC"



export const HYPHEN = "-"
export const OR = "OR"
export const AND = "AND"


export const CLAIMCANCELBUTTON_DEFAULT_ARIALABEL ="Clear button. Caution:This will reset all entries in the form"
export const CLAIMCANCELBUTTON_ARIALABEL="Form entries has been cleared successfully"
export const CLAIMSUBMITBUTTON="Submit"

export const SUBMITCLAIM_ORIGIN="submitClaim_id-originAirport"
export const CLAIMPOINTS_ORIGIN="Origin edit has autocomplete blank. Enter city name to view the list of options"
export const CLAIMPOINTS_DESTINATION="Destination edit has autocomplete blank. Enter city name to view the list of options"

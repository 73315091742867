import React, { Component } from "react";
import { Toast } from "primereact/toast";
import "primereact/toast/toast.min.css";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { withSuspense } from '../../utils';

class Alert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
    };
  }
  componentWillUnmount() {
    this.unlisten();
  }
  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState({showAlert: false});
      show = false;
    });
    const { t } = this.props
    let show = false;
    let currentTime = 0;
    setInterval(() => {
      Object.keys(this.props.splashMessageData).forEach((action) => {
        currentTime = new Date().getTime();
        /** Check the difference of current time and API calling time is greater than specified delay */
        if (
          currentTime - this.props.splashMessageData[action] >
          this.props.splashMessageTimer
        ) {
          show = true;
        }
      });
      if (this.state.showAlert != show) {
        this.setState({ showAlert: show });
        this.toast?.show({
          severity: "info",
          summary: "Info Message",
          detail: t("splashMessage.message"),
          life: 15000,
        });
      }
    }, 1000);
  }
  render() {
    
    return this.state.showAlert ? (
      <Toast ref={(el) => (this.toast = el)} style={{ zIndex: 9999 }} />
    ) : (
      <></>
    );
  }
}
export default withSuspense()(withTranslation()(withRouter(Alert)));
import { combineReducers } from 'redux';
import {
    profileImageReducer
} from '../../ui/mileagecard/reducer';
import { mileageCalculatorReducer } from '../../ui/mileagecalculator/reducer';
import {
    authenticateTransactionReducer,
    buyPointReducer,
    buyPointAcceptReducer,
    logoutTransactionReducer,
    simulateRuleReducer,
    tierUpgradeReducer,
    giftPointsReducer
} from '../../ui/buypoints/reducer'
import recurringTransferReducer from '../../impl/transfer/reducer';
import {muffinReducer, anotherProgramTransferReducer, dateReducer} from '../../impl/transfer/reducer';
import login from '../../ui/login/reducer';
import { commonErrorReducer } from './redux/commonErrorReducer'
import {
    searchMember,
    transferMilesMessage
} from '../../ui/transfermiles/reducers';
import myActivityReducer from '../../ui/myactivity/reducer';
import forgotpsw from '../../ui/forgotpsw/reducer';
import { claimsubmitReducer } from '../../ui/claimsubmit/reducer';
import enrolment from '../../ui/enrolment/reducer';
import { retroClaimRequestsReducer } from '../../ui/claimsummary/reducer'
import  subscribeUnsubscribeReducer from '../../impl/footer/reducer'
import { language, getHeaderDetailsReducer, getNotificationsReducer } from '../../ui/header/reducer';
import { getFooterDetailsReducer } from '../../ui/cmsfooter/reducer';
import {
    newFamilyMemberSearchReducer,
    addFamilyMemberReducer,
    enrolFamilyMemberReducer
} from '../../ui/addfamilymember/reducer'
import {
    profileCompletenessPercentageReducer
} from '../../ui/profile/reducer'

import {
    accountSummaryReducer,
    masterData,
    configurationReducer,
    userInfoReducer,
    logOutReducer,
    fetchCustomerProfileReducer,
    profileDataReducer,
    defaultUserProfileDataReducer,
    currentLoginUserDataReducer,
    privilegesReducer,
    qrCodeImageReducer,
    retriveAccountNomineeReducer,
    masterEntityDataReducer,
    pageReferenceReducer,
    familyListReducer,
    menuReferenceReducer,
    setTierDetailsReducer,
    setAirportDetailsReducer,
    validateMemberDetailReducer,
    addDeleteNomineeReducer,
    cssLoadReducer,
    selectedTabReducer,
    carouselReducer,
    urlInfoReducer,
    customMessageReducer
} from './redux/commonReducer';
import resetpsw from '../../ui/resetpsw/reducer';
import changePsw from '../../ui/security/changepassword/reducer';
import feedback from '../../ui/feedback_v1/reducer';
import changePinReducer from '../../ui/security/changepin/reducer';
import { preferenceUpdateReducer} from '../../ui/preferences/reducer';
import myFlightsReducer from '../../ui/myflight/reducer'
import {
    retrieveNomineesReducer,
    retrieveCustomerReducer,
    addTravellerReducer,
    addNomineeReducer,
    updateTravellerReducer,
    updateNomineeReducer,
    deleteTravellerReducer,
    deleteNomineeReducer
} from '../../ui/corporate/admin/managepeople/reducer'
import { RESET_STATE } from './redux/commonAction';
import { convertPointsReducer } from '../../ui/migratepoints/reducer'
import packagesReducer from '../../ui/club/reducer';
import { recentActivityReducer, offersPartnersReducer } from  '../../ui/dashboard/reducer'
import { loaderReducer } from '../components/fieldbank/loader/reducer'
import mfaReducer from '../../common/components/mfa/reducer'
import { partnersBenefitsReducer } from '../../ui/promotions/reducer'
import { extendExpiryReducer,retrieveQuoteReducer,renewMilesPaymentReducer } from '../../ui/extendexpiry/reducer'
import { retrieveAccountUsersReducer,addAccountUserReducer, updateAccountUserPrivilegeReducer, deleteAccountUserReducer } from '../../ui/manageaccountusers/reducer'
import { retrieveTravelCompanionReducer  } from '../../ui/travelcompanion/reducer';
import { referralDataReducer } from '../../ui/referral/reducer'
import { changeEmailReducer } from '../../ui/modifyuserdetails/reducer';
import { activateReducer } from '../../ui/useractivation/reducer';
import feedbackReducer from '../../ui/feedback_v2/reducer';
import tabsReducer from '../../common/components/tabs/reducer'
import {reactivationReducer} from '../../impl/reactivation/reducer'
import  benefitReducer  from '../../ui/overview/reducer';

/**
 * NOTE: Please add the reducer in alphabetical order
 */
const appReducer = combineReducers({
    accountSummaryReducer,
    activateReducer,
    addAccountUserReducer,
    addDeleteNomineeReducer,
    addFamilyMemberReducer,
    addNomineeReducer,
    addTravellerReducer,
    authenticateTransactionReducer,
    buyPointAcceptReducer,
    buyPointReducer,
    changeEmailReducer,
    changePinReducer,
    changePsw,
    claimsubmitReducer,
    commonErrorReducer,
    configurationReducer,
    convertPointsReducer,
    cssLoadReducer,
    currentLoginUserDataReducer,
    defaultUserProfileDataReducer,
    deleteAccountUserReducer,
    deleteNomineeReducer,
    deleteTravellerReducer,
    enrolFamilyMemberReducer,
    enrolment,
    extendExpiryReducer,
    familyListReducer,
    feedback,
    feedbackReducer,
    fetchCustomerProfileReducer,
    forgotpsw,
    getFooterDetailsReducer,
    getHeaderDetailsReducer,
    getNotificationsReducer,
    giftPointsReducer,
    language,
    loaderReducer,
    login,
    logOutReducer,
    logoutTransactionReducer,
    masterData,
    masterEntityDataReducer,
    menuReferenceReducer,
    mfaReducer,
    mileageCalculatorReducer,
    myActivityReducer,
    myFlightsReducer,
    newFamilyMemberSearchReducer,
    offersPartnersReducer,
    packagesReducer,
    pageReferenceReducer,
    partnersBenefitsReducer,
    preferenceUpdateReducer,
    privilegesReducer,
    profileCompletenessPercentageReducer,
    profileDataReducer,
    profileImageReducer,
    qrCodeImageReducer,
    recentActivityReducer,
    referralDataReducer,
    renewMilesPaymentReducer,
    resetpsw,
    retrieveAccountUsersReducer,
    retrieveCustomerReducer,
    retrieveNomineesReducer,
    retrieveQuoteReducer,
    retrieveTravelCompanionReducer,
    retriveAccountNomineeReducer,
    retroClaimRequestsReducer,
    searchMember,
    setAirportDetailsReducer,
    selectedTabReducer,
    setTierDetailsReducer,
    simulateRuleReducer,
    subscribeUnsubscribeReducer,
    tabsReducer,
    tierUpgradeReducer,
    transferMilesMessage,
    updateAccountUserPrivilegeReducer,
    updateNomineeReducer,
    updateTravellerReducer,
    userInfoReducer,
    validateMemberDetailReducer,
    reactivationReducer,
    carouselReducer,
    benefitReducer,
    urlInfoReducer,
    customMessageReducer,
    recurringTransferReducer,
    muffinReducer,
    anotherProgramTransferReducer,
    dateReducer
});

const rootReducer = (state, action) => {
    // Clear all data in redux store to initial.
    if(action.type === RESET_STATE)
       state = undefined;

    return appReducer(state, action);
 };
 export default rootReducer;
import React from "react";
import { withSuspense } from "../../utils";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ButtonLoader from "./loader/ButtonLoader";
import { trackData } from "../../../common/utils/common.utils";
import { INTERACTION_TYPE } from "../../../common/constants/Constant";
import { CONFIG_SECTION_DEFAULT } from "../../utils/Constants";
import { CLAIMCANCELBUTTON_ARIALABEL } from './Constants';

/**
 * @name Custom Button component(Button).
 * @description Primary button component.
 *
 * @author Rohith
 */
class Button extends React.Component {
constructor(props) {
         super(props);
         this.claimCancelButton = React.createRef();
       }
  onButtonClick(e) {
    if (this.props.enableAnalytics) {
      let combinedProps = { ...this.props._this.props, ...this.props };
      this.props._this.props = combinedProps;
      trackData(
        INTERACTION_TYPE.BUTTON,
        this.props.id,
        this.props._this,
        this.props.customFunctions
      );
    }
    if (this.props.claimCancelButton ) {
      this.claimCancelButton.current.focus()
      this.claimCancelButton.current.ariaLabel= CLAIMCANCELBUTTON_ARIALABEL
      setTimeout(() => {
        this.claimCancelButton.current.blur()
        this.claimCancelButton.current.ariaLabel=this.props.ariaLabel
      }, 500);
    }
    this.props.handleOnClick(e);
  }

    render() {
        const {
            label,
            id,
            testIdentifier,
            enabled,
            className,
            type,
            modal,
            ariaLabel
        } = this.props
        const isEnable = this.props.loaderReducer && this.props.loaderReducer.inprogress != 0

        return (
            <>
                <button
                    aria-label={ariaLabel? ariaLabel: label}
                    id={id}
                    disabled={!enabled || isEnable}
                    onClick={(e) => {this.onButtonClick(e) }}
                    data-test={testIdentifier}
                    type={type}
                    data-dismiss={modal}
                    ref={this.claimCancelButton}
                    className={className}>
                    <ButtonLoader buttonId={id} />&nbsp;{label}
                </button>
            </>
        )

    }
}

Button.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  enabled: PropTypes.bool,
  testIdentifier: PropTypes.string,
};

Button.defaultProps = {
  label: "",
  enabled: true,
  className: "btn btn-primary",
  type: "button",
};

function mapStateToProps(state) {
  return {
    loaderReducer: state.loaderReducer,
    urlInfoReducer: state.urlInfoReducer,
    defaultConfig: state.configurationReducer[CONFIG_SECTION_DEFAULT],
    currentUserData: state.currentLoginUserDataReducer.currentUserData,
    profileData: state.profileDataReducer.profileData
  };
}

export default withSuspense()(
  connect(mapStateToProps, {})(withTranslation()(Button))
);

import {DATE, PROGRAM_TRANSFER,RECURRING_TRANSFER,RETRIEVE_MUFFIN_PARTNER_LIST_SUCCESS} from "./action";

export default function recurringTransferReducer(state, action) {
    if(!state) state = {}
    const { type, payload, operation } = action;
    if (type == RECURRING_TRANSFER) {
        return {
            ...state,
            recurringTransfer: {
                [operation]: payload
            }
        }
    }else {
        return state;
    }
}

export const anotherProgramTransferReducer=(state, action)=> {
    if(!state) state = {}
    const { type, payload, operation } = action;
    if (type == PROGRAM_TRANSFER) {
        return {
            ...state,
            transfer: {
                payload
            }
        }
    }else {
        return state;
    }
}

const initialState = {
        data: [],
        error: null,
      };
export const muffinReducer = (state = initialState, action) => {
    switch (action.type) {
      case RETRIEVE_MUFFIN_PARTNER_LIST_SUCCESS:
        return {
          ...state,
          data: action.payload,
          error: null,
        };   
      default:
        return state;
    }
  };

  export const dateReducer = (state = {data:""}, action) => {
    switch (action.type) {
      case DATE:
        return {
            ...state,
            data: action.payload,
            error: null,
          };   
      default:
        return state;
    }
  };  


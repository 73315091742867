import React from 'react';
import {
    Router as Router,
    Switch,
    Route,
    Redirect,
    Link
} from 'react-router-dom';
import { connect } from 'react-redux'
import psAnalytics  from "@mportal/analytics";
import {
    CONFIG_SECTION_DEFAULT,
    CONFIG_SECTION_ACCOUNT_SUMMARY,
    MOBILE_APP,
    MOBILE_WEBVIEW,
    PROGRAM_TYPE_CORPORATE,
    CONFIG_SECTION_PROFILE,
    CONFIG_SECTION_CUSTOMER_PROFILE
} from '../components/common/utils/Constants'
import {
    isEmptyOrSpaces, scrollToTarget
} from '../components/common/utils'
import {
    fetchConfiguration,
    setPageReference,
    setMenuReference,
    resetError,
    fetchProfileData,
    setURLInfo
} from '../components/common/middleware/redux/commonAction'

import { handleQueryParamsInURL, handlePageRedirectionURL } from '../components/common/utils/sso.utils';
import { loginDetails } from '../components/ui/login/actions';
import loadable from '@loadable/component';
import {
    NAVIGATE_CALLBACK,
    NAVIGATE_403,
    NAVIGATE_500,
    NAVIGATE_PROFILE_COMPLETION,
    NAVIGATE_COMMON_ERROR,
} from '../components/common/utils/urlConstants';
import {
    getItemFromBrowserStorage,
    setItemToBrowserStorage,
    BROWSER_STORAGE_KEY_CURRENCY_CODE,
    BROWSER_STORAGE_KEY_COMPANY_CODE,
    BROWSER_STORAGE_KEY_PROGRAM_CODE,
    BROWSER_STORAGE_KEY_REQUEST_TIMEOUT,
    BROWSER_STORAGE_KEY_PROGRAM_TYPE,
    BROWSER_STORAGE_KEY_APP_ENV,
    BROWSER_STORAGE_KEY_MEMBERSHIP_NO,
    BROWSER_STORAGE_KEY_FIRST_TIME_USER,
    removeItemFromBrowserStorage,
    BROWSER_STORAGE_KEY_IS_REFRESH_TOKEN_FETCHING,
    BROWSER_STORAGE_KEY_TOKEN,
    BROWSER_STORAGE_KEY_DEFAULT_PROGRAM_CODE,
    BROWSER_STORAGE_KEY_CUSTOMER_NO,
    BROWSER_STORAGE_KEY_i18_LANG,
    BROWSER_STORAGE_KEY_DEFAULT_MEMBERSHIP_NO,
    BROWSER_STORAGE_KEY_DATE_FORMAT,
    BROWSER_STORAGE_KEY_DATE_TIME_FORMAT
} from '../components/common/utils/storage.utils';
import { memberTier } from '../components/common/utils/tier.utils';
import Loader from '../components/common/components/fieldbank/loader/Loader';
import { ROUTER_CONFIG } from '../components/common/config/routing';
const _403Page = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'./errors/403'), { fallback: <Loader /> });
const _404Page = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'./errors/404'), { fallback: <Loader /> });
const _500Page = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'./errors/500'), { fallback: <Loader /> });
const PortalBreadcrumb = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'../components/ui/header/PortalBreadcrumb'));
const SidePanel = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'../components/container/SidePanel'));
const _403 = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'../components/ui/errors/403'));
const _404 = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'../components/ui/errors/404'));
const CallbackPage = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'./callback/CallbackPage'), { fallback: <Loader /> });
const HeaderComponent = loadable(() => import(/* webpackChunkName: "addFamilyMembers" */'../components/ui/header/index'));

import { getCurrentPageUri } from '../components/common/utils';
import { havePrivilege, CONTEXT_PAGE } from '../components/common/privileges';
import { TOKEN } from '../components/ui/enrolment/Constants';
import { doGet } from '../components/common/utils/api';
import { setUrl, _URL_ENV_CONFIG_URL, _WHITELIST_LANGUAGES, _DEFAULT_LANGUAGE, _ANALYTICS_CONFIG, _URL_GET_ANALYTICS_CONFIG, _ANALYTICS_CONFIGURATION } from '../components/common/config/config'
import DigitalCard from '../components/common/components/fieldbank/DigitalCard';
import WelcomeMessage from '../components/common/components/fieldbank/WelcomeMessage';
import { getProgramFromDefaultConfigByProgramIdentifier } from '../components/common/utils/configurationFiles.utils';
import { changeLanguage } from '../components/ui/header/actions'
import CommonErrorPage from './errors/CommonErrorPage';
import history from '../components/common/utils/history';
import ErrorBoundary from './errors/ErrorBoundary';
import TitleAnnouncer from './titleAnnouncer';

import { scriptLoader } from '../components/common/utils/scripts.utils';
import {
    checkStartWithScript,
    checkStartWithNoScript,
    getScriptContent,
    getNoScriptContent,
    getTopLevelDomain,
    getPageName,
    setSiteSubSections,
    getHierarchy,
    getPageTitle,
    getPreferredLanguage,
    trackData
} from "../components/common/utils/common.utils";
import { INTERACTION_TYPE } from '../components/common/constants/Constant';

import Alert from "../components/common/components/alert/Alert";
import { scrollToParticularElement } from '../components/common/utils/common.utils';
class Main extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tierName: "",
            isApiLoaded: false,
            isDefaultConfigLoaded: false,
            includeHeader: true,
            mainContentTokenId : "",
            apiTriggerConfig: {},
            imageBannerConfig: {},
            bigBannerConfig: {},
            tierBannerConfig: {},
            currentPath: "",
            prevPath: "",
            pageAnalyticsPath: ""
        }
        this.setValuesToLocalStorageFromDefaultConfig = this.setValuesToLocalStorageFromDefaultConfig.bind(this)
    }
    setAnalyticsData = () => {
        getPageName();
        setSiteSubSections();
        getHierarchy();
        getPageTitle();
    }
    componentWillReceiveProps(nextProps) {
        if (location.href !== this.state.currentPath) {
            this.setState({ prevPath: this.state.currentPath, currentPath: location.href }, () => {
                this.props.setURLInfo({currentPath: this.state.currentPath, prevPath: this.state.prevPath});
                this.setAnalyticsData();
            })
        }
    }
    componentDidMount() {
        getTopLevelDomain();
        handleQueryParamsInURL();
        this.setAnalyticsData();
        getPreferredLanguage();
        if (isEmptyOrSpaces(getItemFromBrowserStorage(TOKEN))) {
            handlePageRedirectionURL()
        } else {
            removeItemFromBrowserStorage(BROWSER_STORAGE_KEY_IS_REFRESH_TOKEN_FETCHING)
        }
        this.getUrlConfiguration().then(res => {

            const { defaultConfig, fetchConfiguration } = this.props
            if (!defaultConfig) {
                fetchConfiguration(CONFIG_SECTION_DEFAULT)
            }
            if (!isEmptyOrSpaces(getItemFromBrowserStorage(TOKEN))) {
                this.fetchProfileConfiguration()
            }
            this.setLanguage()
            const currentPageUri = getCurrentPageUri()
            ROUTER_CONFIG.routes.map(routeConfig => {
                if(currentPageUri == routeConfig.url){
                    const { browserStorage } = routeConfig
                    if (browserStorage) {
                        browserStorage.forEach(storageItem => {
                            //set items to the breowserStorage
                            const { setItems, type } = storageItem
                            if (setItems) {
                                setItems.forEach(item => {
                                    setItemToBrowserStorage(item.key, item.value, type)
                                })
                            }
                        })
    
                    }
                }
            })
            scriptLoader(_ANALYTICS_CONFIG, ()=> {console.log("analytics scripts loaded successfully")})
        }).catch(error => {
            scriptLoader(_ANALYTICS_CONFIG, ()=> {console.log("analytics scripts loaded successfully")})
            console.error("Error occured while loading initial configuration!")
            this.setLanguage()
            if (!this.props.defaultConfig) {
                fetchConfiguration(CONFIG_SECTION_DEFAULT)
            }
        })
    }

    setLanguage = () => {
        let lang = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_i18_LANG)
        if (!lang) {
            lang = _WHITELIST_LANGUAGES.find(e => e.code == _DEFAULT_LANGUAGE)
        } else {
            lang = JSON.parse(lang)
        }
        this.props.changeLanguage(lang)
    }

    fetchProfileConfiguration() {
        const programCodeInBrowser = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE)
        if (!this.props.profileConfig ||
            (this.props.profileConfig &&
                this.props.profileConfig.programCode != programCodeInBrowser)) {
            this.props.fetchConfiguration(CONFIG_SECTION_PROFILE);
        }
        if (getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE) == PROGRAM_TYPE_CORPORATE
            && (!this.props.customerConfig ||
                (this.props.customerConfig &&
                    this.props.customerConfig.programCode != programCodeInBrowser))) {
            this.props.fetchConfiguration(CONFIG_SECTION_CUSTOMER_PROFILE);
        }
    }

    componentDidUpdate(prevProp) {
        handleQueryParamsInURL()
        const newState = {};
        const { defaultConfig, accountSummary, accountSummaryConfig, profileConfig, t } = this.props
        if (defaultConfig &&
            Object.keys(defaultConfig).length > 0 && !this.state.isDefaultConfigLoaded) {
            this.setValuesToLocalStorageFromDefaultConfig(defaultConfig)
            newState["isDefaultConfigLoaded"] = true
        }
        if (accountSummary &&
            this.state.tierName != accountSummary.tierName &&
            accountSummaryConfig &&
            accountSummaryConfig.tiers) {
            newState["tierName"] = accountSummary.tierName
            const memberTierObj = memberTier(accountSummary, accountSummaryConfig, t)
            if (memberTierObj && memberTierObj.tierDetails) {
                const className = memberTierObj.tierDetails.topThemeClass;
                document.body.classList.remove(document.body.classList.item(0));
                document.body.classList.add(className);
            }
        }

        const appEnv = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_APP_ENV)
        document.body.classList.remove(MOBILE_WEBVIEW);
        if (appEnv === MOBILE_APP) {
            document.body.classList.add(MOBILE_WEBVIEW);
        }

        if (prevProp.socialLogin != this.props.socialLogin) {
            newState["socialLogin"] = true
        }

        if (prevProp.forgotPsw != this.props.forgotPsw) {
            window.location.reload();
        }
        if (prevProp.register != this.props.register) {
            window.location.reload();
        }
        if (this.state.confSection &&
            this.state.config &&
            this.props.configCollection[this.state.confSection]) {
            newState["confSection"] = ""
            newState["config"] = false
        }
        if (Object.keys(newState).length) this.setState(newState)
    }

    /**
     * Once the defaultConfig is received,
     * the default program to be be loaded
     * is selected. The criteria to select
     * default program is -  The program
     * should have
     * `programType: "individual"`
     * and
     * `isActive: "true"`
     *
     * The following values from deaultConfig
     * will be stored in Browser Storage.
     *  1.  BROWSER_STORAGE_KEY_COMPANY_CODE in local storage
     *  2.  BROWSER_STORAGE_KEY_REQUEST_TIMEOUT in local storage
     *  3.  BROWSER_STORAGE_KEY_CURRENCY_CODE in local storage
     *  4.  BROWSER_STORAGE_KEY_PROGRAM_CODE in local storage
     * @author Ajmal V Aliyar
     */
    setValuesToLocalStorageFromDefaultConfig(defaultConfig, configuration = undefined, section = undefined) {
        // const { defaultConfig } = this.props
        if (defaultConfig) {
            // const _companyCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE)
            const _programCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE)
            const _defaultProgramCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_PROGRAM_CODE)
            const _timeOut = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_REQUEST_TIMEOUT)
            const _currencyCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_CURRENCY_CODE)
            const _dateFormat = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DATE_FORMAT)
            const _dateTimeFormat = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DATE_TIME_FORMAT)


            setItemToBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE, defaultConfig.companyCode)
            if (isEmptyOrSpaces(_timeOut)) {
                setItemToBrowserStorage(
                    BROWSER_STORAGE_KEY_REQUEST_TIMEOUT,
                    defaultConfig.requestTimedOutInMs
                )
            }
            if (isEmptyOrSpaces(_programCode)
                || isEmptyOrSpaces(_currencyCode)
                || isEmptyOrSpaces(_defaultProgramCode)
                || isEmptyOrSpaces(_dateFormat)
                || isEmptyOrSpaces(_dateTimeFormat)) {
                // Selecting default program based on the program type
                // Get the default program, which has the isActive & isDefault flag as true
                let defaultProgram = undefined
                if (defaultConfig.programs && defaultConfig.programs.length > 0) {
                    // let programType = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE)
                    // programType = programType ? programType : PROGRAM_TYPE_INDIVIDUAL
                    defaultProgram = defaultConfig.programs.find(program => {
                        return program.isActive && program.isDefault
                    })
                }
                if (defaultProgram) {

                    //  3.  set BROWSER_STORAGE_KEY_CURRENCY_CODE in local storage
                    if (isEmptyOrSpaces(_currencyCode)) setItemToBrowserStorage(BROWSER_STORAGE_KEY_CURRENCY_CODE, defaultProgram.defaults.currency)
                    if (isEmptyOrSpaces(_dateFormat)) setItemToBrowserStorage(BROWSER_STORAGE_KEY_DATE_FORMAT, defaultProgram.defaults.dateFormat)
                    if (isEmptyOrSpaces(_dateTimeFormat)) setItemToBrowserStorage(BROWSER_STORAGE_KEY_DATE_TIME_FORMAT, defaultProgram.defaults.dateTimeFormat)
                    //  4.  set BROWSER_STORAGE_KEY_PROGRAM_CODE in local storage
                    if (isEmptyOrSpaces(_programCode)) setItemToBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE, defaultProgram.programCode)
                    if (isEmptyOrSpaces(_defaultProgramCode)) setItemToBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_PROGRAM_CODE, defaultProgram.programCode)
                    if (section && configuration && configuration.programCode != defaultProgram.programCode) {
                        this.props.fetchConfiguration(section)
                    }

                } else {
                    window.location.href = `${NAVIGATE_403}`
                }
            }
        }
    }

    handlePrivileges() {
        const { userPrivileges, accountSummary, corporateNominees } = this.props
        const porgramType = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE)
        const userData = porgramType === PROGRAM_TYPE_CORPORATE ? corporateNominees : accountSummary
        if (userPrivileges && userData) {
            this.setState({
                privileges: havePrivilege(
                    { "url": getCurrentPageUri(), "tab": "" },
                    userData,
                    CONTEXT_PAGE,
                    userPrivileges)
            }, () => {
                const { privileges } = this.state
                if (privileges && privileges.canDisplay) {
                    // window.location.href = "#" + NAVIGATE_403
                }
            })
        }
    }

    renderMainview(routeConfig) {

        const { defaultProps, hasSidePanel } = routeConfig
        return <>
            {
                hasSidePanel &&
                <SidePanel />
            }
            {
                this.renderComponent(routeConfig.component, defaultProps, routeConfig)
            }
        </>
    }

    renderComponent(Component, defaultProps, routeConfig) {
        const pageReference = routeConfig.key && routeConfig.key.pageReference ? routeConfig.key.pageReference : ""
        const menuReference = routeConfig.key && routeConfig.key.menuReference ? routeConfig.key.menuReference : ""
        if (routeConfig.enabled) {
            return this.state.config ?
                (<Loader />) :
                <Component
                    {...defaultProps}
                    browserStorage={routeConfig.browserStorage}
                    refreshStorageData={this.setValuesToLocalStorageFromDefaultConfig}
                    pageReference={pageReference}
                    menuReference={menuReference}
                    setPageInfo={this.setPageInfo}
                    mainContentTokenId={routeConfig.mainContentTokenId}
                    images={routeConfig.images}
                    includeHeader={routeConfig.header != undefined ? routeConfig.header : true}
                    isProtected={routeConfig.isProtected}
                    programIdentifier={routeConfig.programIdentifier}
                    subClassName={routeConfig.subClassName ? routeConfig.subClassName : ""}
                    apiTriggerConfig={routeConfig.apiTriggerConfig ? routeConfig.apiTriggerConfig : {}}
                    imageBannerConfig={routeConfig.imageBannerConfig ? routeConfig.imageBannerConfig : {}}
                    bigBannerConfig={routeConfig.bigBannerConfig ? routeConfig.bigBannerConfig : {}}
                    tierBannerConfig={routeConfig.tierBannerConfig ? routeConfig.tierBannerConfig : {}}
                    accessConfig={routeConfig.accessConfig ? routeConfig.accessConfig : {}}
                />
        } else {
            return <_404 />
        }
    }

    getUrlConfiguration = () => {
        return doGet(_URL_ENV_CONFIG_URL).then(res => {
            setUrl(res.data)
            this.setState({
                isApiLoaded: true
            })
            this.loadAnalyticsConfig();
            return;
        }).catch(error => {
            this.loadAnalyticsConfig();
            this.setState({
                isApiLoaded: true
            })
            return;
        })
    }
    loadAnalyticsConfig = () => {
        if(_ANALYTICS_CONFIGURATION && _ANALYTICS_CONFIGURATION.length) {
            _ANALYTICS_CONFIGURATION.map((item) => {
                if(item.enable) {
                    doGet(_URL_GET_ANALYTICS_CONFIG + item.path).then(res => {
                         if(item.header_script && checkStartWithScript(item.header_script)) {
                            let headScript = document.createElement('script');
                            headScript.innerHTML = getScriptContent(item.header_script);
                            let head = document.getElementsByTagName("head")[0];
                            head.insertBefore(headScript, head.firstChild);
                        } else if(item.header_script) {
                            let headScript = document.createElement('script');
                            headScript.src = item.header_script;
                            headScript.type = "text/javascript";
                            headScript.async = true;
                            let head = document.getElementsByTagName("head")[0];
                            head.insertBefore(headScript, head.firstChild);
                        }

                        if(item.body_script && checkStartWithNoScript(item.body_script)) {
                            let bodyScript = document.createElement('noscript');
                            bodyScript.innerHTML = getNoScriptContent(item.body_script);
                            let body = document.getElementsByTagName("body")[0];
                            body.insertBefore(bodyScript, body.firstChild)
                        } else if(item.body_script) {
                            let bodyScript = document.createElement('script');
                            bodyScript.innerHTML = getScriptContent(item.body_script);
                            let body = document.getElementsByTagName("body")[0];
                            body.insertBefore(bodyScript, body.firstChild)
                        }
                        psAnalytics.initConfiguration(item.id, res.data)
                    })
                }

            })
        }
        psAnalytics.getCountryNameFromTimeZone();
    }

    setPageInfo = (props, configMap) => {
        const newState = {};
        const { pageReference, menuReference, title, t, desrcription, programIdentifier } = props
        const _program = getProgramFromDefaultConfigByProgramIdentifier(this.props.defaultConfig, programIdentifier)
        const programCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE)
        const companyCode = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE)
        const _token = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_TOKEN)
        if(isEmptyOrSpaces(_token) && props.isProtected) {
            window.location.pathname = ROUTER_CONFIG.default.url
            return
        }
        
        if (_program && _program[BROWSER_STORAGE_KEY_PROGRAM_CODE] != programCode) {
            const _programCode = _program[BROWSER_STORAGE_KEY_PROGRAM_CODE]
            let loginProgram = this.props.currentUserData ? this.props.currentUserData.userDetails.programs[_program.programType + "Info"] : [];
            loginProgram = loginProgram.find(lp =>
                lp.companyCode == companyCode &&
                lp.programCode == _programCode
            )
            setItemToBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE, _programCode)
            setItemToBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE, _program.programType)
            setItemToBrowserStorage(BROWSER_STORAGE_KEY_CURRENCY_CODE, _program.defaults.currency)
            setItemToBrowserStorage(BROWSER_STORAGE_KEY_DATE_FORMAT, _program.defaults.dateFormat)
            setItemToBrowserStorage(BROWSER_STORAGE_KEY_DATE_TIME_FORMAT, _program.defaults.dateTimeFormat)
            if (loginProgram) {
                const customerNumber = loginProgram.nominee ? loginProgram.nominee.customerNumber : loginProgram.customerNumber ? loginProgram.customerNumber : ""
                const membershipNumber = loginProgram.membershipNumber
                setItemToBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO, membershipNumber);
                setItemToBrowserStorage(BROWSER_STORAGE_KEY_CUSTOMER_NO, customerNumber);

            }
            window.location.reload()
        }
        newState["includeHeader"] = props.includeHeader != undefined ? props.includeHeader : true
        newState["apiTriggerConfig"] = props.apiTriggerConfig ? props.apiTriggerConfig : {}
        newState["imageBannerConfig"] = props.imageBannerConfig ? props.imageBannerConfig : {}
        newState["bigBannerConfig"] = props.bigBannerConfig ? props.bigBannerConfig : {}
        newState["tierBannerConfig"] = props.tierBannerConfig ? props.tierBannerConfig : {}
        newState["mainContentTokenId"] = props.mainContentTokenId? props.mainContentTokenId : ""
        const profileData = this.props.profileData && this.props.profileData.object
        const seoTitle = title ? t(title) : t(ROUTER_CONFIG.default.page.title)
        const seoDescription = desrcription ? t(description) : t(ROUTER_CONFIG.default.page.description)
        if (!props.isProtected && props.clearToken) {
            removeItemFromBrowserStorage(BROWSER_STORAGE_KEY_TOKEN)
        }
        if(seoTitle) document.title = seoTitle
        document.querySelector('meta[name="description"]').setAttribute('content', seoDescription);
        if (configMap && Object.keys(configMap).length &&
            (!configMap.config ||
                Object.keys(configMap.config).length == 0)) {
            this.props.fetchConfiguration(configMap.confSection)
            newState["config"] = true
            newState["confSection"] = configMap.confSection
        }

        this.handlePrivileges()
        if (this.props.pageRef != pageReference) {
            window.scrollTo(0, 0);
            this.props.setPageReference(pageReference)
            this.props.setMenuReference(menuReference)
            this.props.resetError()
        }

        if (profileData && !profileData.areMandatoryFieldsFilled && getCurrentPageUri() != NAVIGATE_PROFILE_COMPLETION) {
            window.location = `${NAVIGATE_PROFILE_COMPLETION}`
        }

        const _programType =  getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE)
        const _memNo =  getItemFromBrowserStorage(BROWSER_STORAGE_KEY_DEFAULT_MEMBERSHIP_NO)

        if(!isEmptyOrSpaces(_token) &&
            !isEmptyOrSpaces(_programType) &&
            !isEmptyOrSpaces(_memNo)) {
            if(!isEmptyOrSpaces(this.state.memNo) &&
                !isEmptyOrSpaces(this.state.programType) &&
                this.state.memNo != _memNo &&
                this.state.programType == _programType) {
                    window.location.reload();
            } else {
                if(isEmptyOrSpaces(this.state.memNo)) {
                    newState["memNo"] = _memNo
                }
                if(isEmptyOrSpaces(this.state.programType)) {
                    newState["programType"] = _programType
                }
            }
        }

        if (Object.keys(newState).length > 0) {
            this.setState(newState)
        }
        if (this.state.pageAnalyticsPath != location.href && 
            ((this.props.currentUserData && this.props.profileData) || !props?.isProtected)) {
            this.setState({ pageAnalyticsPath: location.href }, () => {
                setTimeout(() => {
                    trackData(INTERACTION_TYPE.PAGE, "", this)
                }, 0)
            })
        }
        // Remove Expanded Quick Nav in Mob View
        const expandedQuickNav = document.getElementsByClassName("collapse show");
        if(expandedQuickNav && expandedQuickNav?.length >0) {
            expandedQuickNav[0].classList.remove("show")
        }       
    }

    setErrorPageInfo = (props) => {
        const newState = {};
        const { pageReference } = props;
        newState["includeHeader"] = props.includeHeader != undefined ? props.includeHeader : true
        if (Object.keys(newState).length > 0) {
            this.setState(newState)
        }
        if (this.props.pageRef != pageReference) {
            this.props.setPageReference(pageReference)
            this.props.resetError()
        }
    }

    hideNavigationSubMenu = () => {
        let item = document.querySelector(".open-sub-menu")
        if(item && item.classList) {
            item.classList.remove("open-sub-menu")
        }
    }
    mapRoutes = (routeConfig, idx) => {
        if (routeConfig.isProtected) {
            if(this.props.profileData && this.props.currentUserData) {
                const membershipNumber = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO)
                const isFirstTimeUser = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_FIRST_TIME_USER)
                return <Route exact path={routeConfig.url} key={"route-" + idx}>
                    {
                        ROUTER_CONFIG.welcomeMessageConfig.enable && isFirstTimeUser == "true" && <WelcomeMessage memno={membershipNumber} key={"canvas-" + idx} />
                    }
                    <main role="main" className={routeConfig.mainClassName} key={"main-" + idx}>
                        <PortalBreadcrumb breadcrumbs={routeConfig.breadcrumbs} />
                        {
                            routeConfig.hasClassFormRow ?
                                <div className="form-row">
                                    {this.renderMainview(routeConfig)}
                                </div> :
                                this.renderMainview(routeConfig)
                        }
                    </main>
                    {
                         (this.props.accountSummary &&  ROUTER_CONFIG.config.showDigitalCard)&&<DigitalCard />
                    }
                </Route>
            } else {
                <Loader />
            }
        }
        return <Route exact path={routeConfig.url} key={"route-" + idx}>
            <main role="main" className={routeConfig.mainClassName} key={"main-" + idx}>
                <PortalBreadcrumb breadcrumbs={routeConfig.breadcrumbs} />
                {
                    routeConfig.hasClassFormRow ?
                        <div className="form-row">
                            {
                                this.renderComponent(routeConfig.component, routeConfig.defaultProps, routeConfig)
                            }
                        </div> : this.renderComponent(routeConfig.component, routeConfig.defaultProps, routeConfig)
                }
            </main>
            {
                this.props.accountSummary && <DigitalCard />
            }
        </Route>
    }

    render() {
        const { isApiLoaded, isDefaultConfigLoaded, includeHeader, apiTriggerConfig, imageBannerConfig, tierBannerConfig,bigBannerConfig, mainContentTokenId } = this.state
        return (
            isApiLoaded && this.props.isCssLoaded ?
                <Router history={history}>
                    <TitleAnnouncer />
                    <ErrorBoundary setErrorPageInfo={this.setErrorPageInfo}>
                        <div onClick={() => this.hideNavigationSubMenu()}>
                            <HeaderComponent
                                includeHeader={includeHeader}
                                apiTriggerConfig={apiTriggerConfig}
                                imageBannerConfig={imageBannerConfig}
                                bigBannerConfig={bigBannerConfig}
                                tierBannerConfig={tierBannerConfig}
                                mainContentTokenId={mainContentTokenId}
                            />
                            {(ROUTER_CONFIG.splashMessageConfig.enable && this.props.splashMessageData) &&
                                <Alert
                                    splashMessageData={this.props.splashMessageData}
                                    splashMessageTimer={ROUTER_CONFIG.splashMessageConfig.timer}
                                />
                            }
                            {
                                isDefaultConfigLoaded &&
                                <Switch>
                                    {
                                        ROUTER_CONFIG && ROUTER_CONFIG.default.url &&
                                        <Route exact path="/">
                                            <Redirect to={ROUTER_CONFIG.default.url + window.location.search} />
                                        </Route>}
                                    {
                                        ROUTER_CONFIG && ROUTER_CONFIG.routes && ROUTER_CONFIG.routes.map((routeConfig, idx) => {
                                            return this.mapRoutes(routeConfig, idx)
                                        })
                                    }
                                    <Route exact path={NAVIGATE_CALLBACK}>
                                        <CallbackPage />
                                    </Route>
                                    <Route exact path={NAVIGATE_403}>
                                        <_403Page setErrorPageInfo={this.setErrorPageInfo}/>
                                    </Route>
                                    <Route exact path={NAVIGATE_500}>
                                        <_500Page setErrorPageInfo={this.setErrorPageInfo}/>
                                    </Route>
                                    <Route exact path={NAVIGATE_COMMON_ERROR}>
                                        <CommonErrorPage setErrorPageInfo={this.setErrorPageInfo}/>
                                    </Route>
                                    <Route render={()=>(<_404Page setErrorPageInfo={this.setErrorPageInfo}/>)} />
                                </Switch>
                            }
                            <ROUTER_CONFIG.footer.component />
                        </div>
                    </ErrorBoundary>
                </Router>
                :
                <Loader />
        )
    }
}


function mapStateToProps(state) {
    return {
        accountSummary: state.accountSummaryReducer.accountSummary,
        login: state.login.loginInfo,
        socialLogin: state.login.socialLogin,
        forgotPsw: state.login.forgotPsw,
        register: state.login.entrolment,
        defaultConfig: state.configurationReducer[CONFIG_SECTION_DEFAULT],
        accountSummaryConfig: state.configurationReducer[CONFIG_SECTION_ACCOUNT_SUMMARY],
        userPrivileges: state.privilegesReducer.privileges,
        corporateNominees: state.retriveAccountNomineeReducer.corporateNominees,
        customerConfig: state.configurationReducer[CONFIG_SECTION_CUSTOMER_PROFILE],
        profileConfig: state.configurationReducer[CONFIG_SECTION_PROFILE],
        pageRef: state.pageReferenceReducer.pageRef,
        qrcodeImage: state.qrCodeImageReducer.qrImage,
        profileData: state.profileDataReducer.profileData,
        configCollection: state.configurationReducer,
        currentUserData: state.currentLoginUserDataReducer.currentUserData,
        isCssLoaded: state.cssLoadReducer,
        splashMessageData: state.loaderReducer?.splashMessageData,
        urlInfoReducer: state.urlInfoReducer
    }
}

const mapDispatchToProps = {
    loginDetails,
    fetchConfiguration,
    setPageReference,
    fetchProfileData,
    setMenuReference,
    resetError,
    changeLanguage,
    setURLInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
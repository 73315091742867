import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { _IMAGES } from '../../components/common/config/config';
import { isEmptyOrSpaces, withSuspense } from '../../components/common/utils';
import { ERROR_PAGE_REFERENCE } from '../../components/common/utils/Constants';
import { BROWSER_STORAGE_KEY_TOKEN, getItemFromBrowserStorage } from '../../components/common/utils/storage.utils';
import { NAVIGATE_CORPORATE_LOGIN, NAVIGATE_CORPORATE_OVERVIEW, NAVIGATE_MEMBER_LOGIN } from '../../components/common/utils/urlConstants';
import CommonMessage from '../../components/ui/errors/CommonMessage';

class _CommonErrorPage extends Component {
    constructor(props) {
        super(props);
        this.accessibilityRef = React.createRef();
    }
    
    componentDidMount() {
        const token = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_TOKEN)
        if (isEmptyOrSpaces(token)) {
            window.location.href = `${NAVIGATE_MEMBER_LOGIN}`
        } else {
            const props = {
                includeHeader: false,
                pageReference: ERROR_PAGE_REFERENCE
            }
            this.props.setErrorPageInfo(props)
        }
    }
    render() {
        const { t } = this.props
        const isToken = !isEmptyOrSpaces(getItemFromBrowserStorage(BROWSER_STORAGE_KEY_TOKEN))
        return <CommonMessage
                    akwardMessage={t('errorMessages.title')+t('errorMessages.desc1')+t('errorMessages.desc2')}
                    accessibilityRef={this.accessibilityRef}
                    id="id-error-message"
                    image={_IMAGES.default}
                    imageType="inline"
                    imgAlt="system outage image"
                    title={`<h2>${t('errorMessages.title')}</h2>`}
                    description1={t('errorMessages.desc1')}
                    description2={t('errorMessages.desc2')}
                    buttons={isToken?[
                        {
                            id: "",
                            label: t("logout.dashboardBtn"),
                            link: NAVIGATE_CORPORATE_OVERVIEW,
                            forceReload: true
                        }
                    ]:[
                        {
                            id: "",
                            label: t("logout.loginBtn"),
                            link: NAVIGATE_CORPORATE_LOGIN,
                            forceReload: true
                        }
                    ]}
        />
    }
}

export default withSuspense()(withTranslation()(_CommonErrorPage));

import { doPost } from '../../common/utils/api'
import {
  _URL_RETRIEVE_RETRO_CLAIM
} from '../../common/config/config';
import { stopApiLoading, startApiLoading } from '../../common/components/fieldbank/loader/action';


export const RETRIEVE_RETRO_CLAIM_SUCCESS = "RETRIEVE_RETRO_CLAIM_SUCCESS";
export const RETRIEVE_RETRO_CLAIM_ERROR = "RETRIEVE_RETRO_CLAIM_ERROR";
export const CONFIG_CLAIM_SUMMARY_SUCCESS = "CONFIG_CLAIM_SUMMARY_SUCCESS";
export const CONFIG_CLAIM_SUMMARY_FAILURE = "CONFIG_CLAIM_SUMMARY_FAILURE";

export const retrieveRetroClaimRequests = (payload) => {
  return async dispatch => {
    dispatch(startApiLoading("retrieveRetroClaimRequests"))
    await doPost(_URL_RETRIEVE_RETRO_CLAIM, payload)
      .then((response) => {
        dispatch(stopApiLoading("retrieveRetroClaimRequests"))
        dispatch({
          type: RETRIEVE_RETRO_CLAIM_SUCCESS,
          payload: response.data
        })
      })
      .catch((error) => {
        dispatch(stopApiLoading("retrieveRetroClaimRequests"))
        dispatch({
          type: RETRIEVE_RETRO_CLAIM_ERROR,
          payload: error
        })
      })
  };
}

/**
 * Returns the next tier based on currentTier
 * @param {stirng} currentTierCode The current tier code
 * @param {string} tiers tiers array
 * @author Somdas M
 */
export const findNextTierByCurrentCode = (currentTierCode, tiers) => {
    if (tiers && currentTierCode) {
        for(let i=0;i<tiers.length; i++) {
            if(tiers[i].code === currentTierCode) {
                const nextTierIndex = i+1;
                if(nextTierIndex < tiers.length) return tiers[nextTierIndex]
            }
        }
    }
    return undefined
}

/**
 * Returns the tier details based in tierCode
 * @param {stirng} tierCode The tier code to be searched
 * @param {string} defaultConfig The default config
 * @author Somdas M
 */
export const getTierByCode = (tierCode, tiers) => {
    if (tiers) {
        return tiers.find(tier => {
            return tier.code === tierCode
        });
    }
    return undefined
}

/**
 * Returns the pointDetails with required pointType
 * @param {stirng} pointType The pointType to be searched
 * @param {string} accountSummary The accountSummary
 * @author Somdas M
 */
export const getPointDetailByType = (pointType, accountSummary) => {
    if(accountSummary && accountSummary.pointDetails) {
        return accountSummary.pointDetails.find(type => {
            return type.pointType === pointType;
        });
    }
    return {}
}

/**
 * Returns the total points of a specified point group
 * @param {stirng} groupType The groupType array to be searched
 * @param {string} accountSummary The accountSummary
 * @author Somdas M
 */
export const getPointDetailByGroupType = (groupType, accountSummary) => {
    let point = 0
    if (accountSummary && accountSummary.pointDetails) {
        accountSummary.pointDetails.map(type => {
            if (groupType.includes(type.pointType)) {
                point += type.points
            }
        })
    }
    return parseInt(point)
}

/**
 * Returns the percentage
 * @param {stirng} value The achieved value
 * @param {string} total The total value
 * @author Somdas M
 */
export const getPercentage = (value, total) => {
    let result = 0;
    if (total != 0) {
        result = (value / total) * 100;
    } else {
        result = 100;
    }
    return result + "%";
}

/**
 * @returns Returns Member Tier Object based on the Base Tier and Sub Tier for the logged in member
 * @param {*} accountSummary 
 * @param {*} accountSummarConfig 
 * @author Gowthaman A
 */
export const memberTier = (accountSummary, accountSummarConfig, t) => {
    let tierName = accountSummary.tierName ? accountSummary.tierName : ""
    let subTierName = accountSummary.subTierName ? accountSummary.subTierName : ""
    let baseTierName = accountSummary.baseTierName ? accountSummary.baseTierName : ""
    if(t) {
        tierName = t(`tiers.${tierName}`) != `tiers.${tierName}` ? t(`tiers.${tierName}`) : tierName
        subTierName = t(`tiers.${subTierName}`) != `tiers.${subTierName}` ? t(`tiers.${subTierName}`) : subTierName
        baseTierName = t(`tiers.${baseTierName}`) != `tiers.${baseTierName}` ? t(`tiers.${baseTierName}`) : baseTierName
    }

    let memberTier = {}
    let tier1
    let tier2
    let tierDetails
    let tierByCode
    if (accountSummarConfig && accountSummarConfig.tiers && Object.keys(accountSummarConfig.tiers).length > 0) {
        tierByCode = getTierByCode(accountSummary.tierCode, accountSummarConfig.tiers)
        if (tierByCode) {
            tierDetails = {
                currentTierCode: tierByCode.code,
                currentTierName: tierByCode.name,
                topThemeClass: tierByCode.topThemeClass,
                baseThemeClass: tierByCode.baseThemeClass,
                subThemeClass: tierByCode.subThemeClass,
                nextTierName: tierByCode.upgrade && Object.keys(tierByCode.upgrade).length > 0 ?
                    tierByCode.upgrade.tierName : "",
                nextTierCode: tierByCode.upgrade && Object.keys(tierByCode.upgrade).length > 0 ?
                    tierByCode.upgrade.tierCode : "",
                main: 'tier1'
            }
        }
    }

    if (accountSummarConfig && accountSummarConfig.subTier && Object.keys(accountSummarConfig.subTier).length > 0) {
        if (accountSummarConfig.subTier.enabled && subTierName) {
            tier1 = tier2 = { name: "", type: "" }
            if (tierName == baseTierName) {
                tier1 = {
                    name: baseTierName,
                    type: accountSummarConfig.subTier.base
                }
                tier2 = {
                    name: subTierName,
                    type: accountSummarConfig.subTier.sub
                }
                tierDetails["main"] = 'tier1'
                tierDetails["sub"] = 'tier2'
                tierDetails["themeClass"] = tierByCode.subThemeClass
            } else if (tierName == subTierName) {
                tier1 = {
                    name: subTierName,
                    type: accountSummarConfig.subTier.sub
                }
                tier2 = {
                    name: baseTierName,
                    type: accountSummarConfig.subTier.base
                }
                tierDetails["main"] = 'tier2'
                tierDetails["sub"] = 'tier1'
                tierDetails["themeClass"] = tierByCode.baseThemeClass
            }
            memberTier = {
                enable: accountSummarConfig.subTier.enabled,
                tier1,
                tier2,
                tierDetails
            }
        } else {
            memberTier = {
                enable: accountSummarConfig.subTier.enabled && subTierName ? accountSummarConfig.subTier.enabled : false,
                tier1: {
                    name: tierName,
                    type: accountSummarConfig.subTier.base
                },
                tierDetails
            }

        }
    } else {
        memberTier = {
            enable: false,
            tier1: {
                name: tierName,
                type: ""
            },
            tierDetails
        }
    }
    return memberTier
}
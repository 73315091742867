import { reloadCss , getApiErrorMessage } from '../../common/utils';
import { doPost, doGet } from '../../common/utils/api';
import {
    _URL_CMS_HEADER_FOOTER_CONTENT, _URL_VIEW_NOTIFICATIONS
} from '../../common/config/config';
import { startApiLoading, stopApiLoading } from '../../common/components/fieldbank/loader/action';

export const SET_LANGUAGE = "SET_LANGUAGE";
export const ERROR = 'ERROR';
export const SET_HEADERDETAILS = "SET_HEADERDETAILS";
export const GET_NOTIFICATIONS ="GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_ERROR = "GET_NOTIFICATIONS_ERROR"

export const changeLanguage = (lang) => {
    return dispatch => {
        reloadCss(lang, dispatch);
        dispatch(setLanguage(lang))
    }
}

export const setLanguage = (lang) => ({
    type: SET_LANGUAGE,
    payload: {
        lang
    }
})

export const getHeaderDetails = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("getHeaderDetails"))
        if(_URL_CMS_HEADER_FOOTER_CONTENT.endsWith(".json")) {
            await doGet(_URL_CMS_HEADER_FOOTER_CONTENT)
            .then((response) => {
                dispatch(stopApiLoading("getHeaderDetails"))
                dispatch({
                    type: SET_HEADERDETAILS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("getHeaderDetails"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
        } else {
            await doPost(_URL_CMS_HEADER_FOOTER_CONTENT, payload)
                .then((response) => {
                    dispatch(stopApiLoading("getHeaderDetails"))
                    dispatch({
                        type: SET_HEADERDETAILS,
                        payload: response.data
                    })
                })
                .catch((error) => {
                    dispatch(stopApiLoading("getHeaderDetails"))
                    dispatch({
                        type: ERROR,
                        payload: { error: getApiErrorMessage(error.response.data.error) }
                    })
                })
        }
    }
}

/**
 * Action call to get the notifications
 * @param {JSON} payload Request payload to be dispatched
 * @author Amrutha J Raj
 */

export const getNotifications = (payload) => {
    return async dispatch => {
        await doPost(_URL_VIEW_NOTIFICATIONS, payload)
            .then((response) => {
                dispatch({
                    type: GET_NOTIFICATIONS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: GET_NOTIFICATIONS_ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

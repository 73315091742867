export const _URL_PARAMS_ACCESS_TOKEN = "access_token"
export const _URL_PARAMS_SSO_SESSION_ID = "sso_session_id"
export const _URL_PARAMS_LANG = "locale"
export const _URL_PARAMS_TAB = "tab"
export const _URL_PARAMS_TRANSACTION_ID = "tid"

/**
 * @author Somdas M
 * @description Method to retrieve value from then respective query param in URL
 * @param String key The key to which value is to be extracted
 */
export const getValueFromQueryParam = (key, href=window.location.pathname) => {
    // let { href } = window.location
    let queryParam = ""
    const keyEquals = key + "="
    if (href.includes("?") && href.includes(keyEquals) && !isEmptyOrSpaces(key)) {
        const param = href.split(keyEquals)
        if (param.length > 1 && param[1]) {
            if (param[1].includes("&")) {
                queryParam = param[1].split("&")[0]
            } else {
                queryParam = param[1]
            }
        }
    }
    return queryParam
}
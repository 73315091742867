
import { doPostResetPassword } from '../../common/utils/api';
import { _URL_FORGOT_PSW_LINK } from '../../common/config/config';
import { 
  stopButtonSpinner, 
  startButtonSpinner 
} from '../../common/components/fieldbank/loader/action';


// Action Type
export const SET_FORGOT_PSW = "SET_FORGOT_PSW";
/**
 * forgot password
 * @param {*} payload 
 */
export const forgotPasswordLink = (payload, id,headers={}) => {
  return async dispatch => {
    dispatch({
      type: SET_FORGOT_PSW,
      payload: {}
    })
    dispatch(startButtonSpinner(id, "forgotPasswordLink"))
    await doPostResetPassword(_URL_FORGOT_PSW_LINK, payload,headers)
    .then((res) => {
      dispatch(stopButtonSpinner(id, "forgotPasswordLink"))
      if(res.data)
      {
        dispatch({
          type: SET_FORGOT_PSW,
          payload: res.data
        })
      }
      return res.data;
      }).catch((error) => {
        dispatch(stopButtonSpinner(id, "forgotPasswordLink"))
        dispatch({
          type: SET_FORGOT_PSW,
          payload: error.response.data
        })
    })
  };
}


import axios from 'axios'
export const axiosInstance = axios.create();
import {
    _DEFAULT_LANGUAGE,
    _URL_FETCH_REFRESH_TOKEN
} from '../config/config'
import {
    getItemFromBrowserStorage,
    BROWSER_STORAGE_KEY_TOKEN,
    BROWSER_STORAGE_KEY_i18_LANG,
    BROWSER_STORAGE_KEY_REFRESH_TOKEN,
    setItemToBrowserStorage,
    BROWSER_STORAGE_KEY_IS_REFRESH_TOKEN_FETCHING
} from './storage.utils'
import { isEmptyOrSpaces, sleep } from '.';
import { ROUTER_CONFIG } from '../config/routing';
import history from './history';

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
    async config => {
        const lan = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_i18_LANG)
        const token = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_TOKEN)
        config.headers = {
            'Content-Type': 'application/json',
            'Accept-Language': lan ? JSON.parse(lan).code : _DEFAULT_LANGUAGE,
            ...config.headers
        }
        if (!isEmptyOrSpaces(token)) {
            config.headers['Authorization'] = 'Bearer ' + token
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

let accessTokenResponse = {}

// Response interceptor for API calls
axiosInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config
    const refreshToken = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_REFRESH_TOKEN)
    let isRefreshTokenFetchInprogress = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_IS_REFRESH_TOKEN_FETCHING)
    if (!isEmptyOrSpaces(refreshToken) &&
        error &&
        error.response &&
        error.response.status == 401 && 
        error.response.data &&
        error.response.data.message && 
        error.response.data.message.includes("Token expired") &&
        !originalRequest._retry) {
        originalRequest._retry = true;
        if (isRefreshTokenFetchInprogress !== 'true') {
            setItemToBrowserStorage(BROWSER_STORAGE_KEY_IS_REFRESH_TOKEN_FETCHING, true)
            accessTokenResponse = await getRefreshAccessToken(_URL_FETCH_REFRESH_TOKEN, { "RefreshToken": refreshToken })
        } else {
            while(isRefreshTokenFetchInprogress === 'true') {
                isRefreshTokenFetchInprogress = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_IS_REFRESH_TOKEN_FETCHING)
                await sleep(1000);
            }
        }
        if (accessTokenResponse) {
            const { data, headers } = accessTokenResponse
            const refreshToken = headers["ps-refreshtoken"]
            const token = headers["ps-token"]
            setItemToBrowserStorage(BROWSER_STORAGE_KEY_IS_REFRESH_TOKEN_FETCHING, false)
            if (data.success && refreshToken && token) {
                var date = new Date();
                date.setDate(date.getDate() + 1);
                setItemToBrowserStorage(BROWSER_STORAGE_KEY_TOKEN, token)
                setItemToBrowserStorage(BROWSER_STORAGE_KEY_REFRESH_TOKEN, refreshToken)
                document.cookie = "token=" + token + "; " + "expires=" + date;
                document.cookie = "refreshtoken=" + refreshToken + "; " + "expires=" + date;
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            }
        }
        return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
});

export const getRefreshAccessToken = (url, headers = {}) => {
    return axiosInstance.get(url, { headers })
        .catch((error) => {
            window.location.href = `${ROUTER_CONFIG.default.url}`
        })
}

export const doPost = (url, payload, headers = {}) => {
    return axiosInstance.post(url, payload, { headers })
}

export const doGet = (url, headers = {}) => {
    return axiosInstance.get(url, { headers })
}

export const doPostEnrol = (url, payload, headers = {}) => {
    return axiosInstance.post(url, payload, { headers })
}

export const doPostResetPassword = (url, payload, headers = {}) => {
    return axiosInstance.post(url, payload, { headers })
}

export const doPostLogin = (url, payload, headers = {}) => {
    const lan = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_i18_LANG)
    const header = {
        'Content-Type': 'application/json',
        'clientId': payload.clientId,
        'secret': payload.secredId,
        'Accept-Language': lan ? JSON.parse(lan).code : _DEFAULT_LANGUAGE,
        ...headers
    }
    return axios.post(url, payload.data, { headers: header })
}


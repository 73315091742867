import React from 'react'
import {
    _URL_SOCIAL_LOGIN, _URL_CALLBACK
} from '../config/config';
var jwtDecode = require('jwt-decode')
import axios from 'axios'
import { isEmptyOrSpaces } from '.';
import { REGEXP_PATTERS } from './Constants';
import psAnalytics from "@mportal/analytics";

export const replaceAll = (string, search, replace) => {
    return !isEmptyOrSpaces(string)?string.split(search).join(replace):string
}

export const stringToRegularExpressions = (string) => {
    let _string =  ""
    if(!isEmptyOrSpaces(string)) {
        for (var i = 0; i < string.length; i++) {
            const ch = string.charAt(i)
            let _ch = ch
            if(REGEXP_PATTERS.includes(ch)) _ch = `\\${ch}`
            _string = _string.concat(_ch)
        }
    }
    return _string
}

export const openPopup = (platform, source="enroll") => {
    const width = 600, height = 600;
    const left = (window.innerWidth / 2) - (width / 2);
    const top = (window.innerHeight / 2) - (height / 2);
    const url = _URL_SOCIAL_LOGIN + "/" + platform + "?redirect_uri=" + encodeURIComponent(_URL_CALLBACK)+'&companyCode=IBS&programCode=PRG14&source=enroll'
    window.open(url, '',
        `toolbar=no, location=no, directories=no, status=no, menubar=no, 
      scrollbar=no, resizable=no, copyhistory=no, width=${width}, 
      height=${height}, top=${top}, left=${left}`
    );
}

export const getUserBySocialMedia = (token, url, headers={}) => { 
    const header = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        ...headers
    }
    return axios.get(url,{ headers: header })
}

export const getActivePage = () =>{
    return location.pathname 
}

export const extractJWT = (token) => {
    return jwtDecode(token);
}

export const getMyFlights = () =>{    
    return axios.get('data/myflights.json').then((response)=>{
       return response.data;
    })
}

export const prepareMasterEntityLookup = (masterEntityData) => {
    const masterEntityLookup = {}
    masterEntityData.forEach(entity => {
        masterEntityLookup[entity.key] = entity.value
    })
    return masterEntityLookup
}

export const prepareMasterEntityLookupFilters = (masterEntityData) => {
    const masterEntityLookupFilters = {}
    masterEntityData.forEach(entity => {
       if(entity.filterFieldDetail){
        masterEntityLookupFilters[entity.key] = entity.filterFieldDetail
       }
    })
    return masterEntityLookupFilters
}

export const getLogoutMessageData = (action) => {
    if(action == "auto") {
        return `ti=alot&de=alod`
    } else {
        return `ti=lot&de=lod`
    }
}

export const scrollToParticularElement = (e, id) => {
    if(e?.keyCode == 32) {
        let elm = document.getElementById(id);
        if(elm) {
            elm.focus();
        }
    }
}
export const trackData = (type, id, data, customFunction) => {
    psAnalytics.pushEvent(type, id, data, customFunction)
}
export const checkStartWithScript = (item) => {
    return item.includes("<script");
};
export const checkStartWithNoScript = (item) => {
    return item.includes("<noscript");
};
export const getScriptContent = (item) => {
    let result = item.match(/<script>(.*?)<\/script>/g).map(function (val) {
        return val.replace(/<\/?script>/g, "");
    });
    if (result && result.length) {
        return result[0];
    }
    return;
};
export const getNoScriptContent = (item) => {
    let result = item.match(/<noscript>(.*?)<\/noscript>/g).map(function (val) {
        return val.replace(/<\/?noscript>/g, "");
    });
    if (result && result.length) {
        return result[0];
    }
    return;
};

/** Method to get the TopLevelDomain for analytics */
export const getTopLevelDomain = () => {
    var re=/\.([^\.]+?)$/;
    let domain = re.exec(location.hostname);
    window.vaAnalytics = {}
    window.topLevelDomain = (domain &&  domain[1]) ?  domain[1] : location.hostname;
    window.vaAnalytics.topLevelDomain = (domain &&  domain[1]) ?  domain[1] : location.hostname;
}

/** Method to get the page name for analytics */
export const getPageName = () => {
    let dataArray = ["https://", "http://", "www.", ".com", "#"];
    let url = window.location.href;
    const urlObj = new URL(url);
    urlObj.search = '';
    url= urlObj.toString();
    dataArray.map(item => {
      url = url.replaceAll(item, "");
    })
    url = url.replaceAll("//", "/");
    url = url.replaceAll("/", ":")
    window.vaAnalytics.pageName =  url;
    return url;
}

/** Method to get the siteSubSections for analytics */
export const setSiteSubSections = (subSection) => {
  let url = getPageName();
  window.vaAnalytics.siteSubSection1 = url? (url.split(":")[1]?  url.split(":")[1]: "" ) : "";
  window.vaAnalytics.siteSubSection2 = url? (url.split(":")[2]?  url.split(":")[2]: "" ) : "";
  window.vaAnalytics.siteSubSection3 = url? (url.split(":")[3]?  url.split(":")[3]: "" ) : "";
  window.vaAnalytics.siteSubSection4 = url? (url.split(":")[4]?  url.split(":")[4]: "" ) : "";
  window.vaAnalytics.siteSubSection5 = url? (url.split(":")[5]?  url.split(":")[5]: "" ) : "";
}

/** Method to get the hierarchy for analytics */
export const getHierarchy = () => {
    let url = getPageName();
    let value = url.split(":");
    if(value && value.length) {
      value.shift();
      value = value.join(":")

    }
    window.vaAnalytics.hierarchy = value? value: "";
 }

 /** Method to get the page title for analytics */
 export const getPageTitle = () => {
    window.vaAnalytics.pageTitle = window.document.title;
 }
 /** Method to get the user prefered language for analytics */
 export const getPreferredLanguage = () => {
    window.vaAnalytics.language =  window.navigator.language || window.navigator.userLanguage;
 }
 /** Method for scroll to particular id*/
 export const handleScroll = (id) => {
    const heightofHeader = document.getElementById("lmp-header") && document.getElementById("lmp-header").offsetHeight;
    const heightOfRefTag = document.getElementById(id) && document.getElementById(id).offsetTop;
    const reference = heightOfRefTag - heightofHeader;
    scrollToTarget(reference);
}

import { 
    API_LOADING_START,
    API_LOADING_STOP,
    BTN_LOADING_START,
    BTN_LOADING_STOP,
    SET_SPLASH_MESSAGE_DATA
} from './action';

const initialVal = {
    clickedElements : [],
    inprogress: 0,
    splashMessageData: {}
}

export function loaderReducer(state=initialVal, action ) {
    const { type, payload } = action;
    if(state == undefined) {
        state = initialVal
    }
    const { inprogress, clickedElements } = state
    let id = ''
    switch(type) {
        case API_LOADING_START:
        case API_LOADING_STOP:
            const { pointer } = payload
            return {
                ...state,
                inprogress: (inprogress<=0&&pointer<0?0:inprogress+pointer)
            }
        case BTN_LOADING_START:
            id = payload.id
            if(!clickedElements.includes(id)) clickedElements.push(id)
            return {
                ...state,
                clickedElements
            }
        case BTN_LOADING_STOP:
            id = payload.id
            if(clickedElements.includes(id)) clickedElements.splice(clickedElements.indexOf(id), 1);
            return {
                ...state,
                clickedElements,
            }
            case SET_SPLASH_MESSAGE_DATA:
                let splashMessageData = payload;
                if(state?.splashMessageData && Object.keys(state?.splashMessageData).length) {
                    splashMessageData = {...state?.splashMessageData, ...payload}
                }
                return  {
                    ...state,
                    splashMessageData: splashMessageData
                }          
    }
    return state
}
import React from 'react';
import { withSuspense } from '../../../utils';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/**
 * @name Button Loader Component.
 * @description Button Loader Component to show a loader on top of button.
 * 
 * @author Rohith
 */
class ButtonLoader extends React.Component {

    render() {
        const { buttonId } = this.props
        const isLoading = this.props.loaderReducer && this.props.loaderReducer.clickedElements.includes(buttonId)

        return (
            isLoading?<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />:<></>
        )

    }
}

ButtonLoader.propTypes = {
    buttonId: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {
        loaderReducer: state.loaderReducer
    }
}

export default withSuspense()(connect(mapStateToProps, {})(withTranslation()(ButtonLoader)));
import { startButtonSpinner, stopButtonSpinner } from "../../common/components/fieldbank/loader/action";
import { _URL_ISSUE_CERTIFICATE, _URL_MUFFIN_PARTNER_LIST, _URL_RECURRING_TRANSFER } from "../../common/config/config";
import { fetchAccountSummary } from "../../common/middleware/redux/commonAction";
import { getApiErrorMessage } from "../../common/utils";
import { doPost } from "../../common/utils/api";
import { fetchProfileData } from "../../common/middleware/redux/commonAction";


export const RECURRING_TRANSFER = "RECURRING_TRANSFER"
export const RECURRING_TRANSFER_ERROR = "RECURRING_TRANSFER_ERROR"
export const PROGRAM_TRANSFER="PROGRAM_TRANSFER"
export const PROGRAM_TRANSFER_ERROR="PROGRAM_TRANSFER_ERROR"
export const RETRIEVE_MUFFIN_PARTNER_LIST_SUCCESS =
  "RETRIEVE_MUFFIN_PARTNER_LIST_SUCCESS";
export const DATE = "DATE"  

export const submitRecurringTransfer = (payload, operation, buttonId) => {
    return async dispatch => {
        dispatch(startButtonSpinner(buttonId, "submitRecurringTransfer"))
        dispatch({
            type: RECURRING_TRANSFER,
            payload: {}
        })
        await doPost(_URL_RECURRING_TRANSFER, payload).then((res) => {
            dispatch(stopButtonSpinner(buttonId, "submitRecurringTransfer"))
             dispatch(fetchProfileData())
            if (res) {
                dispatch({
                    type: RECURRING_TRANSFER,
                    payload: res.data,
                    operation
                })
                if (res.data.object.pointsTransferred > 0) {
                    dispatch(fetchAccountSummary())
                }
            }
        }).catch((error) => {
            dispatch(stopButtonSpinner("recurring", "recurringTransfer"))
            try {
                dispatch({
                    type: RECURRING_TRANSFER_ERROR,
                    payload: { error: getApiErrorMessage(error.response.data) }
                })
            } catch (error) {
                dispatch({
                    type: RECURRING_TRANSFER_ERROR,
                    payload: error
                })
            }
        })
    };
}

export const submitRecurringTransferClear = () => {
    return async dispatch => {
        dispatch({
            type: RECURRING_TRANSFER,
            payload: {}
        })
    };
}

export const submitAnotherProgramTransfer = (payload, operation, buttonId) => {
    return async dispatch => {
        dispatch(startButtonSpinner(buttonId, "submitAnotherProgramTransfer"))
        dispatch({
            type: PROGRAM_TRANSFER,
            payload: {}
        })
        await doPost(_URL_ISSUE_CERTIFICATE, payload).then((res) => {
            dispatch(stopButtonSpinner(buttonId, "submitAnotherProgramTransfer"))
            if (res) {
                dispatch({
                    type: PROGRAM_TRANSFER,
                    payload: res.data,
                    operation
                })
                if (res.status === 200) {
                    dispatch(fetchAccountSummary())
                }
            }
        }).catch((error) => {
            dispatch(stopButtonSpinner("recurring", "recurringTransfer"))
            try {
                dispatch({
                    type: PROGRAM_TRANSFER_ERROR,
                    payload: { error: getApiErrorMessage(error.response.data) }
                })
            } catch (error) {
                dispatch({
                    type: PROGRAM_TRANSFER_ERROR,
                    payload: error
                })
            }
        })
    };
}

export const submitAnotherProgramTransferClear = () => {
    return async dispatch => {
        dispatch({
            type: PROGRAM_TRANSFER,
            payload: {}
        })
    };
}

// Muffin partner list

export const retrieveMuffinPartnerList = (payload={}) => {
    return async dispatch => {
        await doPost(_URL_MUFFIN_PARTNER_LIST, payload)
            .then((response) => {
                dispatch({
                    type: RETRIEVE_MUFFIN_PARTNER_LIST_SUCCESS,
                    payload: response?.data?.object?.partnerList
                })
                dispatch({
                    type:DATE,
                    payload:response?.data?.object?.currentDateTime
                })
            })
            .catch((error) => {
                console.error("Error fetching muffin partner list:", error);
            })
    };
}